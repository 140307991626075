import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { opcoesFormatacaoMoedaBr } from "components/funcoesGlobais";
import * as ReactBt from "react-bootstrap";
import "./cardProduto.css";
import { dadosProdProp } from "./cardProduto.interface";
import { adicionarRemoverFavorito, useCardProduto } from "./useCardProduto";

const CardProduto: React.FC<dadosProdProp> = (props) => {
  const { produto, className, pgFavorito, urlImagem, empresa, usuario } = useCardProduto(props);

  return (
    <ReactBt.Card className={`card-produto position-relative carousel-produtos ${className}`} id={produto.id}>
      {parseInt(produto.promocao) === 1 && parseInt(produto.promocao_valida) === 1 && (
        <ReactBt.Badge bg="danger" className="badge-card">
          PROMOÇÃO
        </ReactBt.Badge>
      )}
      {produto.posicao_mais_vendido !== undefined && <ReactBt.Badge bg="dark" className="badge-card badge-mais-vendido">{`${produto.posicao_mais_vendido}º`}</ReactBt.Badge>}
      <div
        className={`badge-card badge-favorito ${parseInt(produto.produto_favoritado) === 1 ? "favorito" : ""} ${produto.posicao_mais_vendido ? "segundo" : ""}`}
        onClick={() => adicionarRemoverFavorito(produto.id, usuario, pgFavorito)}
      >
        <FontAwesomeIcon icon={faHeart} size="2x" />
      </div>
      <div className="div-card-imagem">
        <ReactBt.CardImg src={urlImagem} className="imagem-principal" />
        {parseInt(produto.bebida_alcoolica) === 1 && parseInt(empresa.exibir_aviso_bebida_alcoolica) === 1 && <ReactBt.CardImg src={"/imgs/bebidas_menores.png"} className="tag-inferior-direito" />}
      </div>
      <ReactBt.Card.Body>
        <ReactBt.Card.Title className="nome-produto text-center">{produto.nome}</ReactBt.Card.Title>
        {parseInt(produto.aleitamento) === 1 && (
          <>
            <p className="mensagem-aleitamento mb-0">O Ministério da Saúde adverte: O aleitamento materno evita infecções e alergias e é recomendado até os dois anos de idade ou mais.</p>
            <p className="mensagem-aleitamento">O Ministério da Saúde adverte: Após os seis meses de idade continue amamentando seu filho e ofereça novos alimentos. </p>
          </>
        )}
      </ReactBt.Card.Body>
      <ReactBt.Card.Body className="div-precos-quantidade">
        <div className={`precos mb-1`}>
          {parseInt(empresa.ocultar_precos) === 0 && (
            <>
              <span className={`preco preco-antigo ${parseInt(produto.promocao) && parseInt(produto.promocao_valida) ? "" : "invisble"}`}>
                {parseInt(produto.promocao) && parseInt(produto.promocao_valida) ? `R$ ${parseFloat(produto.valor).toLocaleString("pt-BR", opcoesFormatacaoMoedaBr)}` : ""}
              </span>
              <div className="d-flex align-items-center justify-content-between">
                <span className={`preco ${parseInt(produto.promocao) && parseInt(produto.promocao_valida) ? "text-danger" : ""}`}>
                  {parseInt(produto.promocao) && parseInt(produto.promocao_valida)
                    ? `R$ ${parseFloat(produto.valor_promocional).toLocaleString("pt-BR", opcoesFormatacaoMoedaBr)}`
                    : `R$ ${parseFloat(produto.valor).toLocaleString("pt-BR", opcoesFormatacaoMoedaBr)}`}
                </span>
                <span className={`preco-total invisible`}>R$ 0,00/Kg</span>
              </div>
            </>
          )}
        </div>
        <div className="div-quantidade d-block d-lg-flex justify-content-between ">
          <ReactBt.FormControl size="sm" placeholder="Quantidade" type="number" className="w-100 w-lg-50" />
          <ReactBt.Button type="button" variant="success" size="sm" className="w-100 w-lg-50 mt-1 mt-lg-0">
            Comprar
          </ReactBt.Button>
        </div>
      </ReactBt.Card.Body>
    </ReactBt.Card>
  );
};

export { CardProduto };
