import { EntrarCadastrar } from "pages/entrarCadastrar/EntrarCadastrar";
import { Inicio } from "pages/inicio/Inicio";
import { MeusDados } from "pages/meusDados/MeusDados";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { VerificarEmail } from "pages/verificarEmail/VerificarEmail";
import { ListagemProdutos } from "pages/listagemProdutos/ListagemProdutos";

const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/entrar" element={<EntrarCadastrar />} />
          <Route path="/meus-dados/:aba" element={<MeusDados />} />
          <Route path="/verificar_email/:email" element={<VerificarEmail />} />
          <Route path="/produtos/:paramUrl1/:paramUrl2" element={<ListagemProdutos />} />
          <Route path="/produtos/:paramUrl1" element={<ListagemProdutos />} />
          <Route path="/produtos" element={<ListagemProdutos />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export { AppRoutes };
