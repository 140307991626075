import { Button } from "react-bootstrap";
import "./modalCookies.css";
import { useModalCookies } from "./useModalCookies";

const ModalCookies = () => {
  const { confimarCookies, abrirAbaTermo } = useModalCookies();

  return (
    <div id="modal-cookies" className="border-1 border container fade show">
      <div className="conteudo-modal">
        <p className="texto">
          Para uma maior segurança e melhor experiência estamos utilizando cookies de acordo com a nossa
          <button className="ps-1 pe-0 btn-limpo-cookie" onClick={abrirAbaTermo}>
            Política de privacidade e segurança
          </button>
          . Ao continuar a navegação entendemos que você está ciente e de acordo.
        </p>
        <Button onClick={confimarCookies}>OK</Button>
      </div>
    </div>
  );
};

export { ModalCookies };
