import React from "react";
import * as funcoes from "./useBannerVertical";
import "./bannerVertical.css";
import { BannerVerticalProp } from "./bannerVertical.interface";

const BannerVertical: React.FC<BannerVerticalProp> = (prop) => {
  const { className = "", imagem = `${process.env.REACT_APP_SITE_URL}imgs/banner_secundario.png`, href = "" } = prop;

  return (
    <div
      className={`banner-vertical rounded ${className}`}
      style={{
        backgroundImage: `url(${imagem})`,
      }}
      onClick={funcoes.encaminhar(href)}
    ></div>
  );
};

export { BannerVertical };
