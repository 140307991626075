import { jsonParse, redirecionar } from "components/funcoesGlobais";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const deslogar = () => {
  localStorage.removeItem("token");
  localStorage.usuario = JSON.stringify({ logado: false });
  localStorage.removeItem("estoqueAtivo");
  redirecionar("");
};

export const useMeusDados = (abas: any) => {
  const { aba } = useParams();

  const usuario = jsonParse("usuario");

  useEffect(() => {
    const abaValida = aba && abas[aba];
    const abaAtiva = document.getElementById(`aba-${abaValida ? aba : "perfil"}`);
    if (abaAtiva) abaAtiva.classList.add("ativo");
    // eslint-disable-next-line
  }, []);

  return {
    aba,
    usuario,
  };
};
