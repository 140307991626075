import { faCheck, faExclamation, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { alertaProp } from "./alerta.interface";

export const useAlerta = (props: alertaProp) => {
  const { mensagem, tempo = 3000, setFechar, variante = "success", className } = props;
  const icones: any = { success: faCheck, danger: faExclamation, warning: faQuestion };

  setTimeout(() => {
    const alerta = document.querySelector(".alerta");

    if (alerta?.classList.contains("show")) {
      alerta.classList.remove("show");
      alerta.classList.add("hide");

      setTimeout(() => {
        setFechar(false);
      }, 400);
    }
  }, tempo);

  return {
    mensagem,
    setFechar,
    variante,
    className,
    icones,
  };
};
