import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./cabecalho.css";
import { ListaCategorias } from "./ListaCategorias";
import { siteUrl } from "components/funcoesGlobais";
import { Button, Container, Dropdown, FloatingLabel, Form, FormControl, InputGroup, Modal, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "react-bootstrap";
import { ativarLojaSelecionada, confirmarCep, exibirFecharMenuCategorias, pesquisar, popularLojas, useCabecalho } from "./useCabecalho";
import { faCartShopping, faChevronDown, faHeart, faHouseChimney, faLocationDot, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import ReactInputMask from "react-input-mask";
import { useForm } from "react-hook-form";

const Cabecalho = () => {
  const { lojas, usuario, estoqueAtivo, exibirModalCep, exibirModalLoja, exibirModalAltModo, setLojas, setExibirModalCep, setExibirModalLoja, setExibirModalAltModo } = useCabecalho();

  const {
    register,
    trigger,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<{ cep: string }>();

  return (
    <>
      <header>
        <Navbar id="navbar-cabecalho" expand="lg" className="fixed-top" bg="white">
          <Container>
            <NavbarBrand href="/" className="py-0 me-5">
              <img src={siteUrl("imgs/logos/logotipo_progenbr.png", process.env.REACT_APP_SITE_URL)} alt="Logo da empresa" height="48" />
            </NavbarBrand>
            <Navbar.Toggle aria-controls="navBarResponsivo" />
            <Navbar.Collapse id="navBarResponsivo">
              <Form className="d-flex me-5 w-100" onSubmit={(e) => [e.preventDefault(), pesquisar(e)]}>
                <InputGroup>
                  <FormControl type="search" placeholder="Pesquise aqui o produto ou a marca que deseja" aria-label="Pesquise" />
                  <Button variant="primary" id="btn-buscar-produto" type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup>
              </Form>
              <Nav className="ms-auto mb-lh-0">
                <NavItem className="m-auto">
                  {usuario.logado ? (
                    <Dropdown>
                      <Dropdown.Toggle as={NavLink} className="d-flex" id="link-entre-cadastre-se">
                        <FontAwesomeIcon icon={faUser} />
                        <div>
                          <span id="entre"> Olá,</span>
                          <span id="cadastre-se">{usuario.nome?.split(" ")[0]}</span>
                        </div>
                        <FontAwesomeIcon icon={faChevronDown} className="fs-6 ms-3" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/meus-dados/perfil">Meu Perfil</Dropdown.Item>
                        <Dropdown.Item href="/meus-dados/enderecos">Endereços</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <NavLink className={`d-flex`} id="link-entre-cadastre-se" href="/entrar">
                      <FontAwesomeIcon icon={faUser} />
                      <div>
                        <span id="entre">Entre</span>
                        <span id="cadastre-se">ou Cadastre-se</span>
                      </div>
                    </NavLink>
                  )}
                </NavItem>
                <NavItem>
                  <NavLink className="d-flex" id="link-favoritos" href="/produtos/meus-favoritos">
                    <FontAwesomeIcon icon={faHeart} />
                    <div>
                      <span id="meus">Meus</span>
                      <span id="favoritos">Favoritos</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="position-relative" id="link-carrinho" href="/">
                    <FontAwesomeIcon icon={faCartShopping} />
                    <span className="position-absolute translate-middle badge rounded-pill bg-success">
                      <span id="qtd-itens-carrinho">5</span>
                      <span className="visually-hidden">Quantidade itens no carrinho</span>
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Nav id="barra-menu">
          <Container>
            <div id="modo-local-entrega" className="d-flex me-4">
              <FontAwesomeIcon icon={faLocationDot} />
              <button className={`botao-limpo text-white ${Object.entries(estoqueAtivo).length < 2 ? "carregando" : ""}`} id="div-modo-local-entrega" onClick={() => setExibirModalAltModo(true)}>
                <span id="modo-entrega"></span>
                <span id="local-entrega">Digitar CEP</span>
              </button>
            </div>
            <li className="nav-item" id="menu-categorias">
              <NavLink href="#" role="button" id="link-lista-categorias" onClick={exibirFecharMenuCategorias}>
                Comprar por categorias
                <FontAwesomeIcon icon={faChevronDown} className="ms-4" />
              </NavLink>
              <ListaCategorias />
            </li>
            <NavLink href="/produtos/ofertas">Ofertas</NavLink>
            <NavLink href="/">Jornal de Ofertas</NavLink>
            <NavLink href="/">Contato</NavLink>
          </Container>
        </Nav>
      </header>
      <Modal show={exibirModalCep} onHide={() => [setValue("cep", ""), setExibirModalCep(false)]} onShow={() => trigger()}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar CEP de atendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="float-cep" label="CEP:" className="mb-2">
            <ReactInputMask
              mask={"99999-999"}
              placeholder="CEP:"
              maskChar={""}
              {...register("cep", {
                required: "O campo CEP é obrigatório",
                validate: {
                  cep: (cep) => {
                    const regex = /^[0-9]{5}-?[0-9]{3}$/;
                    return regex.test(cep) || "CEP inválido";
                  },
                },
              })}
              className={`form-control ${errors.cep ? " is-invalid" : "is-valid"}`}
              id="input-cep"
              onKeyUp={() => trigger()}
            />
            <Form.Control.Feedback type="invalid" className="lh-1 d-block">
              {errors.cep?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => [setExibirModalCep(false), setExibirModalAltModo(true)]}>
            Voltar
          </Button>
          <Button variant="primary" className={errors.cep ? "disabled" : ""} onClick={(e: any) => confirmarCep(e, trigger, setExibirModalCep, getValues, setError, setValue)}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={exibirModalAltModo} onHide={() => setExibirModalAltModo(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Escolha um modo de entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-around p-3">
            <button className="botao-limpo botoes-modo-entrega shadow" onClick={() => [setExibirModalCep(true), setExibirModalAltModo(false)]}>
              <FontAwesomeIcon icon={faHouseChimney} size="3x" className="mb-2" />
              RECEBER <strong> EM CASA</strong>
            </button>
            <button className="botao-limpo botoes-modo-entrega shadow" onClick={() => [setExibirModalLoja(true), setExibirModalAltModo(false), popularLojas(setLojas)]}>
              <FontAwesomeIcon icon={faLocationDot} size="3x" className="mb-2" />
              RETIRAR <strong>NA LOJA</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={exibirModalLoja} onHide={() => setExibirModalLoja(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Retirar na loja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="lista-lojas">
            <Form onSubmit={(e) => [e.preventDefault(), setExibirModalLoja(false), ativarLojaSelecionada(lojas)]} id="retirar-na-loja-form">
              {lojas.map((loja: any, index: number) => {
                return (
                  <div className="form-check" key={index} id={`radio-loja-${index}`}>
                    <input className="form-check-input" type="radio" name="lojaAtiva" id={`loja-${loja.id}`} defaultChecked={loja.id === estoqueAtivo.id} value={index} />
                    <label className="form-check-label" htmlFor={`loja-${loja.id}`}>
                      <span className="nome-loja">
                        <strong>{loja.nome}</strong>
                        <small className="ps-2">[{loja.cep}]</small>
                      </span>
                      <div className="endereco">
                        <span className="text-muted cidade">
                          {loja.cidade} - {loja.estado}
                        </span>
                        <span className="text-muted rua">
                          {loja.endereco}, {loja.numero}
                        </span>
                      </div>
                    </label>
                  </div>
                );
              })}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => [setExibirModalLoja(false), setExibirModalAltModo(true)]}>
            Voltar
          </Button>
          <Button variant="primary" type="submit" form="retirar-na-loja-form">
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export { Cabecalho };
