import axios from "axios";
import { jsonParse, primeiraLetraMaiuscula, redirecionar, setUsuarioConsulta } from "components/funcoesGlobais";
import api from "services/api";
import { useState, useContext, useEffect } from "react";
import { UsuarioContext } from "context/UsuarioContext";

export const validarCep = async (trigger: any, getValues: any, setValue: any, setError: any) => {
  if (!(await trigger("cep"))) return false;
  const cep = getValues("cep");
  const inputCep = document.querySelector("input[name=cep]");
  inputCep?.classList.add("is-valid");
  (inputCep as HTMLInputElement).disabled = true;

  const inputsOperacao = document.querySelectorAll("input[name=logradouro],select[name=estado],input[name=cidade],input[name=bairro]");
  inputsOperacao.forEach((input) => ((input as HTMLInputElement).disabled = true));

  await axios
    .post(
      process.env.REACT_APP_URL_API_CEP + "endereco.php",
      JSON.stringify({
        operacao: "CONSULTA",
        cep: parseInt(cep.replace("-", "")),
      })
    )
    .then((consulta) => {
      const endereco = consulta.data.endereco;
      if (endereco) {
        setValue("logradouro", "");
        setValue("estado", "");
        setValue("bairro", "");
        setValue("cidade", "");

        if (endereco.logradouro && endereco.logradouro !== endereco.cidade) setValue("logradouro", `${endereco.tipo_logradouro} ${endereco.logradouro}`.trim());
        if (endereco.uf) setValue("estado", endereco.uf);
        if (endereco.bairro) setValue("bairro", endereco.bairro);
        if (endereco.cidade) setValue("cidade", endereco.cidade);
      } else {
        setError("cep", { type: "manual", message: "CEP não encontrado ou inválido." });
      }
    })
    .catch((e) => console.error(e))
    .then(() => {
      document.querySelectorAll("input:disabled,select:disabled").forEach((input) => ((input as HTMLInputElement).disabled = false));
    });
};

export const keyUpTelefone = (getValues: any, setTel: any) => {
  setTel(getValues("telefone").replace(/\s|\(|\)|_|-/g, "").length);
};

export const submitForm = async (usuario: any, evento: any, trigger: any, getValues: any, setExibirModal: any, setAlerta: any, setExibirAlerta: any, setAtualizarEnderecos: any) => {
  evento.preventDefault();

  if (!(await trigger())) return;

  const dados = getValues();

  dados.padrao = (document.getElementById("check-end-padrao") as HTMLInputElement).checked ? 1 : 0;
  dados.cliente = usuario.cliente;
  dados.cep = dados.cep.replace("-", "");
  dados.quem_recebe = primeiraLetraMaiuscula(dados.quem_recebe);
  dados.logradouro = primeiraLetraMaiuscula(dados.logradouro);
  dados.bairro = primeiraLetraMaiuscula(dados.bairro);

  if (!dados.id) delete dados.id;

  api[dados.id ? "put" : "post"](dados.id ? `crm_empresas_enderecos.php/${dados.id}` : `crm_empresas_enderecos.php`, dados).then((res) => {
    if (res.status === 200) {
      setExibirModal(false);
      setAlerta({ mensagem: `Endereço ${dados.id ? "atualizado" : "cadastrado"} com sucesso!`, variante: "success" });
      setExibirAlerta(true);
      setAtualizarEnderecos(true);
    }
  });
};

export const formatarTel = (tel: string | null) => {
  if (!tel) return "Telefone inválido";
  return tel.length === 11 ? tel.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : tel.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
};

export const validarCampo = async (campo: string, trigger: any) => {
  if (!(await trigger(campo))) return false;
  document.querySelector(`.modal [name=${campo}]`)?.classList.add("is-valid");
};

export const ufEstados = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
  DF: "Distrito Federal",
};

export const removerEndereco = async (id: number, cliente: number, setAtualizarEnderecos: any, setAlerta: any, setExibirAlerta: any, exibirModalConfirmar: any) => {
  const decricaoEndereco = (document.querySelector(`#endereco-${id} .descricao span`) as HTMLSpanElement)?.innerText;
  if (!(await exibirModalConfirmar(`Remover o endereço "${decricaoEndereco}"?`))) return false;

  api
    .delete(`crm_empresas_enderecos.php/${id}`, { data: { cliente: cliente } })
    .then((res) => {
      if (res.status === 200) {
        setAlerta({ mensagem: `Endereço "${decricaoEndereco}" removido com sucesso!`, variante: "success" });
        setExibirAlerta(true);
      }
    })
    .then(() => {
      setAtualizarEnderecos(true);
    });
};
const carregandoInputs = (carregando: boolean) => {
  document
    .getElementById("modal-endereco")
    ?.querySelectorAll("form div.form-floating")
    .forEach((div) => (div as HTMLDivElement).classList[carregando ? "add" : "remove"]("carregando"));
};

export const popularEndereco = async (id: number, setValue: any, setExibirModal: any, setTel: any, setAtualizarEnderecos: any) => {
  await setExibirModal(true);
  carregandoInputs(true);
  api
    .get(`crm_empresas_enderecos.php/${id}`)
    .then(async (consulta) => {
      const endereco = consulta.data.crm_empresa_endereco;

      if (endereco) {
        Object.entries(endereco).forEach((valor) => {
          const input = document.querySelector(`#form-endereco input[name=${valor[0]}]:not([type=radio])`) as HTMLInputElement;
          const valorInput = valor[1] as string;

          if (!input || !valorInput) return false;
          input.value = valorInput;
        });

        setTel(endereco.telefone.replace(/[^\d\w]/g, "").length);
        setValue("descricao", endereco.descricao);
        setValue("quem_recebe", endereco.quem_recebe);
        setValue("cpf", endereco.cpf);
        setValue("telefone", endereco.telefone);
        setValue("cep", endereco.cep);
        setValue("logradouro", endereco.logradouro);
        setValue("numero", endereco.numero);
        setValue("complemento", endereco.complemento);
        setValue("bairro", endereco.bairro);
        setValue("cidade", endereco.cidade);
        setValue("estado", endereco.estado);
        setValue("id", endereco.id);

        const inputPadrao = document.querySelector("#check-end-padrao") as HTMLInputElement;
        if (parseInt(endereco.padrao) && !inputPadrao.checked) inputPadrao.click();

        document.querySelectorAll(`#form-endereco :disabled`).forEach((desabilitado) => ((desabilitado as HTMLInputElement).disabled = false));

        setAtualizarEnderecos(true);
      }
    })
    .then(() => carregandoInputs(false));
};

export const useEnderecos = () => {
  const [enderecos, setEnderecos] = useState<any>([]);
  const [consultandoEnd, setConsultandoEnd] = useState<boolean>(true);
  const { setAlerta, setExibirAlerta, exibirModalConfirmar } = useContext(UsuarioContext);
  const usuario = jsonParse("usuario");
  const [exibirModal, setExibirModal] = useState<boolean>(false);
  const [atualizarEnderecos, setAtualizarEnderecos] = useState<boolean>(false);
  const [tel, setTel] = useState(0);

  useEffect(() => {
    if (usuario.logado) {
      api
        .get("crm_empresas_enderecos.php", {
          params: {
            cliente: usuario.cliente,
          },
        })
        .then((consulta) => {
          api.get("usuarios.php/consultar_por_token").then((attUser) => {
            setUsuarioConsulta(attUser.data.usuario);
            if (atualizarEnderecos) setAtualizarEnderecos(false);
            setEnderecos(consulta.data.crm_empresa_endereco);
            setConsultandoEnd(false);
          });
        });
    } else {
      redirecionar("");
    }
  }, [usuario.logado, usuario.cliente, atualizarEnderecos]);

  return {
    tel,
    usuario,
    setAlerta,
    enderecos,
    exibirModal,
    consultandoEnd,
    setExibirAlerta,
    exibirModalConfirmar,
    setTel,
    setExibirModal,
    setAtualizarEnderecos,
  };
};
