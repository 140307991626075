export const siteUrl = (url: string, urlBase = process.env.REACT_APP_URL_API): string => {
  return urlBase + url;
};

export const eVazio = (dado: any): boolean => {
  if (dado === "" || dado === null || dado === undefined) return true;

  if (typeof dado === "object") {
    if (Array.isArray(dado)) {
      return dado.length === 0;
    } else {
      return Object.keys(dado).length === 0;
    }
  }

  return false;
};

export const exibirErroInput = (input: HTMLElement, texto: false | string = false): void => {
  input.classList.add("is-invalid");
  if (texto && input.nextElementSibling && input.nextElementSibling.classList.contains("invalid-feedback")) input.nextElementSibling.innerHTML = texto;
};

export const limparValidacaoForm = (idForm: string): void => {
  const formulario = document.getElementById(idForm);
  if (formulario === null) return;

  formulario.querySelectorAll("input").forEach((input) => {
    input.classList.remove("is-valid", "is-invalid");
  });

  formulario.querySelectorAll(".invalid-feedback,.valid-feedback").forEach((elemento) => (elemento.innerHTML = ""));
};

export const redirecionar = (url: string) => {
  const urlBase = siteUrl("", process.env.REACT_APP_SITE_URL);
  const baseBarra = urlBase[urlBase.length - 1] === "/";

  if (url[0] === "/" && baseBarra) url = url.slice(1);

  window.location.href = siteUrl(url, process.env.REACT_APP_SITE_URL);
};

export const cpfValido = (cpf: string) => {
  const invalidos: any = ["00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"];
  const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  const numeroCPF = cpf.replace(/\D+/g, "");

  if (!regex.test(cpf)) return false;
  if (invalidos.includes(numeroCPF)) return false;

  let soma = 0;
  let resto;

  for (let i = 0; i < numeroCPF.length - 1; i++) soma += parseInt(numeroCPF.charAt(i)) * (numeroCPF.length - i);

  resto = 11 - (soma % 11);
  if (resto > 9) resto = 0;

  return resto === parseInt(numeroCPF.charAt(numeroCPF.length - 1));
};

export const primeiraLetraMaiuscula = (string: string) => {
  const palavras = string.split(" ");
  let stringMaiuscula = "";

  palavras.forEach((palavra) => (stringMaiuscula += palavra.charAt(0).toUpperCase() + palavra.slice(1).toLocaleLowerCase() + " "));

  return stringMaiuscula.trim();
};

export const jsonParse = (string: string) => {
  const usuario = string.toLocaleLowerCase() === "usuario";
  try {
    const obj: { [i: string]: any } = JSON.parse(localStorage[string]);
    return obj;
  } catch (error) {
    const obj: { [i: string]: any } = {};
    if (usuario) obj.logado = false;
    return obj;
  }
};

export const setUsuarioConsulta = (usuario: { [i: string]: any }): {} => {
  const usuarioAtualizado: any = { logado: true };

  Object.entries(usuario).forEach((campoValor) => {
    if (camposUsuario.includes(campoValor[0])) usuarioAtualizado[campoValor[0]] = campoValor[1];
  });

  return (localStorage.usuario = JSON.stringify(Object.assign({ logado: true }, usuarioAtualizado)));
};

export const camposUsuario = [
  "id",
  "cep",
  "cliente",
  "data_nascimento",
  "email",
  "email_verificado",
  "genero",
  "logradouro",
  "loja_retirada",
  "modo_entrega",
  "nome",
  "numero",
  "endereco_entrega_valido",
];

export const opcoesFormatacaoMoedaBr = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
};
