import { jsonParse, redirecionar } from "components/funcoesGlobais";
import { useEffect, useState } from "react";
import api from "services/api";

export const popularCampos = async (setValue: any, setTel: any, setCarregando: any) => {
  await api
    .get("usuarios.php/consultar_por_token")
    .then((consulta) => {
      const usuario = consulta.data.usuario;
      if (usuario.nome) setValue("nome", usuario.nome);
      if (usuario.cpf) setValue("cpf", usuario.cpf);
      if (usuario.email) setValue("email", usuario.email);
      if (usuario.data_nascimento) setValue("data_nascimento", usuario.data_nascimento);
      if (usuario.telefone) {
        setValue("telefone", usuario.telefone);
        setTel(usuario.telefone.replace(/\s|\(|\)|_|-/g, "").length);
      }
      if (usuario.genero) {
        setValue("genero", usuario.genero);
      }

      const spanNome = document.getElementById("span-nome");
      if (spanNome) spanNome.innerText = usuario.nome.split(" ")[0];
      setCarregando(false);
    })
    .catch((e) => console.error(e));
};

export const clickSalvar = async (id: number, trigger: any, getValues: any, setEmEdicao: any, setMostrarAlerta: any, setAlerta: any) => {
  if (!(await trigger())) return false;

  const dados = getValues();

  api
    .put(`usuarios.php/${id}`, dados)
    .then((api) => {
      if (api.status === 200 && api.data.resultado) {
        setEmEdicao(false);
        const usuario = jsonParse("usuario");

        Object.entries(api.data.usuario).forEach((campoValor) => {
          if (usuario[campoValor[0]]) usuario[campoValor[0]] = campoValor[1];
        });

        localStorage.usuario = JSON.stringify(usuario);
        (document.getElementById("span-nome") as HTMLSpanElement).innerText = usuario.nome.split(" ")[0];
        document.querySelectorAll(".formulario input,select").forEach((input) => input.classList.remove("is-valid", "is-invalid"));
        setAlerta({ mensagem: "Alterações feitas com sucesso!" });
        setMostrarAlerta(true);
      }
    })
    .catch((erros) => {
      if (erros.response.status === 412) {
        (document.querySelector("input[name=email]") as HTMLInputElement).value = "";
        setAlerta({ mensagem: erros.response.data.mensagem, variante: "danger" });
        setMostrarAlerta(true);
      }
      console.error(erros);
    });
};

export const enviarEmailVerificacao = (usuario: any, emailEnviado: boolean, setEmailEnviado: any, setAlerta: any, setMostrarAlerta: any) => {
  if (usuario.email_verificado) return true;
  if (emailEnviado) {
    setAlerta({ mensagem: "E-mail já enviado, verifique sua caixa de spam ou aguarde 30 segundos para enviar um novo email", variante: "warning" });
    setMostrarAlerta(true);
    return true;
  }

  const span = document.getElementById("span-vericado");
  span?.classList.add("enviando");
  usuario.token_acesso = localStorage.getItem("token");

  api
    .get("usuarios.php/verificar_email", { params: usuario })
    .then((api) => {
      if (api.status === 200 && api.data.resultado) {
        setAlerta({ mensagem: "E-mail enviado, lembre de verificar a caixa de spam", variante: "success" });
        setEmailEnviado(true);
        setMostrarAlerta(true);

        setTimeout(() => setEmailEnviado(false), 30000);
      }

      if (api.status === 201) {
        setAlerta({ mensagem: "E-mail já foi verificado.", variante: "success" });
        setMostrarAlerta(true);
        localStorage.usuario = JSON.stringify(Object.assign({}, usuario, { email_verificado: 1 }));
      }
    })
    .catch((erros) => {
      setAlerta({ mensagem: "Algo deu errado no envio do e-mail, tente novamente ou entre em contato conosco", variante: "danger" });
      setMostrarAlerta(true);
      console.error(erros);
    })
    .then(() => span?.classList.remove("enviando"));
};

export const clickCancelarEdicaoInfos = (usuario: any, setEmEdicao: any) => {
  setEmEdicao(false);
  if (usuario.nome) (document.querySelector("input[name=nome]") as HTMLInputElement).value = usuario.nome;
  if (usuario.email) (document.querySelector("input[name=email]") as HTMLInputElement).value = usuario.email;
  if (usuario.cpf) (document.querySelector("input[name=cpf]") as HTMLInputElement).value = usuario.cpf;
  if (usuario.data_nascimento) (document.querySelector("input[name=data_nascimento]") as HTMLInputElement).value = usuario.data_nascimento;
  if (usuario.telefone) (document.querySelector("input[name=telefone]") as HTMLInputElement).value = usuario.telefone;
  if (usuario.genero) ((document.querySelector("select[name=genero]") as HTMLSelectElement).querySelector(`option[value=${usuario.genero}]`) as HTMLOptionElement).selected = true;
};

export const usePerfil = (setValue: any, trigger: any) => {
  const [carregando, setCarregando] = useState(true);
  const usuario = jsonParse("usuario");
  const [emEdicao, setEmEdicao] = useState(false);
  const [tel, setTel] = useState(0);
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [alerta, setAlerta] = useState({ mensagem: "", variante: "success" });
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  useEffect(() => {
    usuario.logado ? popularCampos(setValue, setTel, setCarregando) : redirecionar("");
    // eslint-disable-next-line
  }, []);

  const keyUp = async (e: any) => {
    const input = e.target;
    const valido = await trigger(input.name);

    if (input.name === "telefone") setTel(input.value.replace(/\s|\(|\)|_|-/g, "").length);

    input.classList.remove("is-valid", "is-invalid");
    input.classList.add(valido ? "is-valid" : "is-invalid");
  };

  return {
    tel,
    alerta,
    usuario,
    emEdicao,
    carregando,
    emailEnviado,
    mostrarAlerta,
    keyUp,
    setAlerta,
    setEmEdicao,
    setEmailEnviado,
    setMostrarAlerta,
  };
};
