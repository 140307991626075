import api from "services/api";

export const useModalCookies = () => {
  const confimarCookies = () => {
    const modal = document.getElementById("modal-cookies");
    if (modal !== null) {
      modal.classList.add("hide");
      modal.classList.remove("show");
    }
    localStorage.setItem("cookiesAceitos", "aceitos");
  };

  const abrirAbaTermo = () => api.get("empresa.php").then((api) => window.open(api.data.empresa.url_termo_privacidade, "_blank"));

  return {
    confimarCookies,
    abrirAbaTermo,
  };
};
