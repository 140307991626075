import { Button, Modal } from "react-bootstrap";

interface interfaceModalConfirmar {
  setShow: any;
  show: boolean;
}

const ModalConfirmar: React.FC<interfaceModalConfirmar> = (props) => {
  const { setShow, show } = props;

  return (
    <Modal size="sm" show={show} onHide={() => setShow(false)} id="modal-confirmar">
      <Modal.Body id="texto-modal-confirmar"></Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="secondary" size="sm">
          Cancelar
        </Button>
        <Button variant="primary" size="sm">
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ModalConfirmar };
