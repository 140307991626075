import axios from "axios";
import { jsonParse, siteUrl } from "components/funcoesGlobais";
import { useEffect, useState } from "react";
import api from "services/api";

const useScroll = () => {
  useEffect(() => {
    function verificarScroll() {
      if (window.scrollY >= 42) {
        document.getElementById("navbar-cabecalho")?.classList.add("navbar-fixo");
      } else {
        document.getElementById("navbar-cabecalho")?.classList.remove("navbar-fixo");
      }
    }

    window.addEventListener("scroll", verificarScroll);

    return () => {
      window.removeEventListener("scroll", verificarScroll);
    };
  }, []);
};

export function exibirFecharMenuCategorias() {
  const listaCategorias = document.querySelector("#lista-categorias");

  if (listaCategorias?.classList.contains("d-none")) {
    listaCategorias?.classList.remove("d-none");
  } else {
    document.querySelector(".lista-grupos")?.classList.add("d-none");
    document.querySelector(".link-lista-grupos, .lista-grupos")?.classList.remove("mostrar");
    setTimeout(() => {
      listaCategorias?.classList.add("d-none");
    }, 200);
  }
  setTimeout(() => {
    document.querySelector("#link-lista-categorias")?.classList.toggle("mostrar");
    document.querySelector("#lista-categorias")?.classList.toggle("mostrar");
  }, 10);
}

const useVerificarEndereco = (usuario: any, estoqueAtivo: any) => {
  useEffect(() => {
    const elementoModo = document.getElementById("modo-entrega") as HTMLSpanElement;
    const elementoEndereco = document.getElementById("local-entrega") as HTMLSpanElement;
    const enderecoCep = jsonParse("enderecoCep");
    const enderecoGeo = jsonParse("enderecoGeo");

    if (elementoEndereco && elementoModo && estoqueAtivo) {
      //EXIBE O ENDEREÇO PELO CEP
      if (Object.entries(enderecoCep).length && localStorage.cepDigitado) {
        elementoModo.innerText = "Receber em";
        elementoEndereco.innerText = `${enderecoCep.tipo_logradouro ? enderecoCep.tipo_logradouro : ""} ${enderecoCep.logradouro} - ${enderecoCep.cep.toString().replace(/(\d{5})(\d{3})/, "$1-$2")}`;
        return;
      }

      if (!usuario.logado) {
        if (Object.entries(enderecoGeo).length) {
          elementoModo.innerText = "Receber em";
          elementoEndereco.innerText = `${enderecoGeo.logradouro} - ${enderecoGeo.uf}`;
          return;
        }
        elementoModo.innerText = "";
        elementoEndereco.innerText = `Digite o CEP`;
        return;
      }

      if (usuario.logado && usuario.modo_entrega) {
        if (usuario.modo_entrega.toLocaleLowerCase() === "casa") {
          if (parseInt(usuario.endereco_entrega_valido) === 1) {
            elementoModo.innerText = "Receber em";
            elementoEndereco.innerText = `${usuario.logradouro}${usuario.numero !== null ? `, ${usuario.numero}` : ""}`;
          } else {
            elementoModo.innerText = "Retirar em";
            elementoEndereco.innerText = ` ${estoqueAtivo.endereco} - ${estoqueAtivo.cep}`;
          }
          return;
        }

        if (usuario.modo_entrega.toLocaleLowerCase() === "casa") {
          elementoModo.innerText = "Retirar em";
          elementoEndereco.innerText = ` ${estoqueAtivo.endereco} - ${estoqueAtivo.cep}`;
          return;
        }
      }
    }
    // eslint-disable-next-line
  }, [estoqueAtivo, usuario.logado]);
};

export const confirmarCep = async (evento: any, trigger: any, setExibirModalCep: any, getValues: any, setError: any, setValue: any) => {
  if (!(await trigger())) return false;
  evento.target.classList.add("disabled");
  evento.target.innerText = "Consultando...";
  const cep = getValues("cep");
  const consultas: any = {};

  await axios
    .post(
      process.env.REACT_APP_URL_API_CEP + "endereco.php",
      JSON.stringify({
        operacao: "CONSULTA",
        cep: parseInt(cep.replace("-", "")),
      })
    )
    .then((consulta) => (consultas.endereco = consulta.data.resultado ? consulta.data.endereco : false));

  if (consultas.endereco === false) {
    evento.target.classList.remove("disabled");
    evento.target.innerText = "Confirmar";
    setValue("cep", "");
    return setError("cep", { type: "manual", message: "CEP não encontrado." });
  }

  await api.get("estoque_armazens.php", { params: { cep_cliente: cep } }).then((consultaCep) => (consultas.estoque = consultaCep.data.total === 0 ? false : consultaCep.data.rows[0]));

  if (consultas.estoque === false) {
    evento.target.classList.remove("disabled");
    setValue("cep", "");
    evento.target.innerText = "Confirmar";
    setError("cep", {
      type: "manual",
      message: "Não atendemos a CEP digitado.",
    });
  } else {
    const elementoModo = document.getElementById("modo-entrega") as HTMLSpanElement;
    const elementoEndereco = document.getElementById("local-entrega") as HTMLSpanElement;

    if (elementoModo !== null && elementoEndereco !== null) {
      localStorage.cepDigitado = cep;
      consultas.estoque.consultar = false;
      localStorage.estoqueAtivo = JSON.stringify(consultas.estoque);
      localStorage.enderecoCep = JSON.stringify(consultas.endereco);
      setExibirModalCep(false);
      window.location.reload();
    }
  }
};

export const popularLojas = (setLojas: any) => {
  api.get("estoque_armazens.php", { params: { sort: "nome", order: "asc" } }).then((consulta) => {
    const campoLojas = document.getElementById("lista-lojas");
    const lojas = consulta.data.rows;
    if (campoLojas === null) return;
    setLojas(lojas);
  });
};

export const ativarLojaSelecionada = (lojas: any) => {
  const lojaSelecionadaIndex = (document.getElementById("retirar-na-loja-form")?.querySelector("input:checked") as HTMLInputElement).value;
  const loja = lojas[lojaSelecionadaIndex];
  loja.consultar = false;
  localStorage.estoqueAtivo = JSON.stringify(loja);
  localStorage.removeItem("enderecoCep");
  localStorage.removeItem("cepDigitado");
  window.location.reload();
};

export const useCabecalho = () => {
  const [exibirModalCep, setExibirModalCep] = useState(false);
  const [exibirModalAltModo, setExibirModalAltModo] = useState(false);
  const [exibirModalLoja, setExibirModalLoja] = useState(false);
  const [lojas, setLojas] = useState<any>([]);
  const [estoqueAtivo, setEstoqueAtivo] = useState(jsonParse("estoqueAtivo"));
  const usuario = jsonParse("usuario");

  useScroll();
  useVerificarEndereco(usuario, estoqueAtivo);

  const atualizarEstoque = setInterval(() => {
    if (Object.entries(estoqueAtivo).length < 1) {
      setEstoqueAtivo(jsonParse("estoqueAtivo"));
    } else {
      clearInterval(atualizarEstoque);
    }
  }, 500);

  return {
    lojas,
    usuario,
    estoqueAtivo,
    exibirModalCep,
    exibirModalLoja,
    exibirModalAltModo,
    setLojas,
    setExibirModalCep,
    setExibirModalLoja,
    setExibirModalAltModo,
  };
};

export const pesquisar = (e: any) => {
  const pesquisa = e.target.querySelector("input[type=search]").value;

  if (pesquisa) window.location.href = siteUrl(`produtos/pesquisa/${pesquisa}`, process.env.REACT_APP_SITE_URL);
};
