import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icones from "@fortawesome/free-solid-svg-icons";
import { exibirSubLista, useListarCategorias } from "./useListarCategorias";

const ListaCategorias = () => {
  const { categorias } = useListarCategorias();
  return (
    <ul id="lista-categorias" className="d-none">
      {categorias.length &&
        categorias.map((categoria: any, i) => (
          <li key={`grupo-${categoria.id}`} className="position-relative">
            <a href={`/produtos/categoria/${categoria.descricao}-${categoria.id}`} className="link-categoria link-lista-grupos" onMouseEnter={exibirSubLista}>
              <span>{categoria.descricao}</span>
              <FontAwesomeIcon icon={icones.faChevronRight} />
            </a>
            <ul className="lista-grupos d-none">
              {Object.entries(JSON.parse(categoria.grupos_produtos)).length &&
                Object.entries(JSON.parse(categoria.grupos_produtos)).map((idDescricao: any, chave: number) => (
                  <li key={`subgrupo-${idDescricao[0]}`}>
                    <a href={`/produtos/grupo/${idDescricao[1]}-${idDescricao[0]}`} className="link-grupo">
                      {idDescricao[1]}
                    </a>
                  </li>
                ))}
            </ul>
          </li>
        ))}
    </ul>
  );
};

export { ListaCategorias };
