import { Cabecalho } from "components/cabecalho/Cabecalho";
import { Rodape } from "components/rodape/Rodape";
import "./verificarEmail.css";
import { useVerificarEmail } from "./useVerificarEmail";

const VerificarEmail = () => {
  useVerificarEmail();

  return (
    <>
      <Cabecalho />
      <div className="container-verificando">
        <span className="fs-2 verificando" id="span-verificando">
          Verificando, aguarde...
        </span>
      </div>
      <Rodape />
    </>
  );
};

export { VerificarEmail };
