import "./categoriaDestaque.css";
import { ReactSVG } from "react-svg";
import { eVazio, siteUrl } from "components/funcoesGlobais";

interface CategoriaDestaqueProps {
  texto: string;
  icone: string;
  href?: string;
}

const CategoriaDestaque: React.FC<CategoriaDestaqueProps> = (props) => {
  const { texto, icone, href } = props;
  const iconeUrl: string = eVazio(icone) ? siteUrl(`svg/setores/vazio.svg`, process.env.REACT_APP_URL_GESTOR) : icone;

  return (
    <a className="categoria" href={href}>
      <div className="icone">
        <ReactSVG src={iconeUrl} />
      </div>
      <span>{texto.split(" ")[0].toLocaleUpperCase("pt-br")}</span>
    </a>
  );
};

export { CategoriaDestaque };
