import { faArrowDown19, faArrowDownAZ, faArrowUp19, faArrowUpAZ, faAward, faFaceFrown, faHeartCrack, faPercent } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { jsonParse, primeiraLetraMaiuscula } from "components/funcoesGlobais";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import api from "services/api";

export const gerarDados = (consulta: any) => {
  return {
    produtos: consulta[0].data.rows,
    totalProdutos: consulta[0].data.total,
  };
};

export const gerarArrayPaginas = (ultimaPagina: number) => {
  const arrayPaginas = [];
  for (let i = 0; i < ultimaPagina; i++) {
    arrayPaginas.push(i);
  }
  return arrayPaginas;
};

export const useConsultar = (
  setor: any,
  setDados: any,
  setCarregando: any,
  numeroPagina: number,
  ordenacao: any,
  filtrosConsulta: any,
  dados: any,
  paramUrl: any,
  paramUrl2: any,
  setOrdenacao: any,
  setNumeroPagina: any,
  setDadosMobile: any,
  setSetor: any,
  setExibirModalLogin: any
) => {
  paramUrl = paramUrl !== undefined && !Number.isNaN(parseInt(paramUrl)) ? parseInt(paramUrl) : paramUrl;

  const links = [
    { url: "produtos.php", filtros: filtrosConsulta },
    { url: "empresa.php", filtros: {} },
    { url: `setores.php`, filtros: { id: paramUrl !== undefined ? paramUrl : 0 } },
  ];

  const [armazemInicial, setArmazemInicial] = useState(jsonParse("estoqueAtivo")?.id);

  useEffect(() => {
    if (!localStorage.estoqueAtivo) {
      const verificarArmazem = setInterval(() => {
        if (localStorage.estoqueAtivo) {
          clearInterval(verificarArmazem);
          setArmazemInicial(jsonParse("estoqueAtivo")?.id);
        }
      }, 200);
      return;
    }

    // eslint-disable-next-line
    filtrosConsulta = Object.assign(filtrosConsulta, { armazem_inicial: armazemInicial });
    if (paramUrl !== undefined) {
      if (typeof paramUrl === "number") {
        filtrosConsulta.setor = paramUrl;
      } else {
        delete links[2];
        if (paramUrl.toLocaleLowerCase().trim() === "ofertas") {
          setSetor(Object.assign(setor, { titulo: "Ofertas" }));
          links[0].filtros = Object.assign({}, filtrosConsulta, { promocao: 1 });
        } else if (paramUrl.toLocaleLowerCase().trim() === "mais-vendidos") {
          setSetor(Object.assign(setor, { titulo: "Mais vendidos" }));
          links[0].url = "produtos_mais_vendidos.php";
        } else if (paramUrl.toLocaleLowerCase().trim() === "pesquisa" && paramUrl2) {
          setSetor(Object.assign(setor, { titulo: paramUrl2 }));
          links[0].filtros = Object.assign({}, filtrosConsulta, { search: paramUrl2 });
        } else if (paramUrl.toLocaleLowerCase().trim() === "categoria" && !Number.isNaN(paramUrl2.trim().split("-")[1])) {
          const descricaoId = paramUrl2.trim().split("-");
          setSetor(Object.assign(setor, { titulo: primeiraLetraMaiuscula(descricaoId[0]) }));
          links[0].filtros = Object.assign({}, filtrosConsulta, { setor: descricaoId[1] });
        } else if (paramUrl.toLocaleLowerCase().trim() === "grupo" && !Number.isNaN(paramUrl2.trim().split("-")[1])) {
          const descricaoId = paramUrl2.trim().split("-");
          setSetor(Object.assign(setor, { titulo: primeiraLetraMaiuscula(descricaoId[0]) }));
          links[0].filtros = Object.assign({}, filtrosConsulta, { grp: descricaoId[1] });
        } else if (paramUrl.toLocaleLowerCase().trim() === "destaques") {
          setSetor(Object.assign(setor, { titulo: "Destaques" }));
          links[0].filtros = Object.assign({}, filtrosConsulta, { destaque: 1 });
        } else if (paramUrl.toLocaleLowerCase().trim() === "meus-favoritos") {
          const token = localStorage.getItem("token");
          if (!token || (token && token.trim() === "")) return setExibirModalLogin(true);
          links[0] = { url: "produtos_favoritos.php/favoritos_por_token", filtros: Object.assign(filtrosConsulta, { token_acesso: token }) };
          setSetor(
            Object.assign(setor, {
              titulo: "Produtos Favoritos",
              mensagem: "Você ainda não adicionou nenhum produto a sua lista de favoritos.",
              icone: faHeartCrack,
              mensagem2: "Clique no botão com um coração no produto que desejar adicionar aqui.",
            })
          );
        } else {
          setSetor(Object.assign(setor, { titulo: "Todos" }));
        }
      }
    } else {
      setSetor(Object.assign(setor, { titulo: "Todos" }));
    }

    window.scroll({ top: 0, behavior: "smooth" });
    if (document.querySelector(".dropdown-menu.dropdown-menu.show")) document.getElementById("btn-dropdown")?.click();
    if (dados.produtos[numeroPagina] !== undefined && dados.produtos[numeroPagina].length > 0 && !ordenacao.alterado) return;
    setCarregando(true);

    axios.all(links.map((link) => api.get(link.url, { params: link.filtros }))).then((consultas) => {
      const consultaProdutos = consultas[0].data;
      const consultaEmpresa = consultas[1].data.empresa.ocultar_valores;

      if (consultas[2] && consultas[2].data.total) {
        const descricaoSetor = (consultas[2].data.rows[0].descricao as string).toLocaleLowerCase("pt-br").split(" ")[0];
        setSetor(Object.assign(setor, { titulo: descricaoSetor.charAt(0).toUpperCase() + descricaoSetor.slice(1) }));
      }

      setDados({
        totalProdutos: consultaProdutos.total,
        produtos: !ordenacao.alterado ? Object.assign({}, dados.produtos, { [numeroPagina]: consultaProdutos.rows }) : { [numeroPagina]: consultaProdutos.rows },
        ocultarPrecos: consultaEmpresa,
      });

      if (numeroPagina === 1) setDadosMobile({ produtos: consultaProdutos.rows, pagina: numeroPagina });
      if (ordenacao.alterado) setNumeroPagina(1);

      setOrdenacao(Object.assign(ordenacao, { alterado: false }));
      setCarregando(false);
    });
    // eslint-disable-next-line
  }, [ordenacao.sort, ordenacao.order, numeroPagina, armazemInicial]);
};

export const ordemDropdown: any = {
  nome: {
    asc: {
      icone: faArrowDownAZ,
      descricao: "Nome",
    },
    desc: {
      icone: faArrowUpAZ,
      descricao: "Nome",
    },
  },
  valor: {
    asc: {
      icone: faArrowDown19,
      descricao: "Menor preço",
    },
    desc: {
      icone: faArrowUp19,
      descricao: "Maior preço",
    },
  },
  desconto: {
    desc: {
      icone: faPercent,
      descricao: "Maiores descontos",
    },
  },
  vendidos: {
    desc: {
      icone: faAward,
      descricao: "Mais vendidos",
    },
  },
};

export const gerarPaginacaoLoop = (numeroPagina: number, ultimaPagina: number) => {
  let inicioPaginacao: number;
  let fimPaginacao: number;

  if (numeroPagina <= 5) {
    inicioPaginacao = 2;
    fimPaginacao = 8;
  } else if (numeroPagina > 4 && numeroPagina <= ultimaPagina - 5) {
    inicioPaginacao = numeroPagina - 2;
    fimPaginacao = numeroPagina + 2;
  } else {
    inicioPaginacao = ultimaPagina - 6;
    fimPaginacao = ultimaPagina;
  }

  return {
    inicio: inicioPaginacao < 1 ? 1 : inicioPaginacao,
    fim: ultimaPagina - 1 < fimPaginacao ? ultimaPagina - 1 : fimPaginacao,
  };
};

export const consultarApiMobile = (filtrosConsulta: any, dadosMobile: any, setDadosMobile: any, ultimaPagina: any) => {
  api.get("produtos.php", { params: Object.assign({}, filtrosConsulta, { pagina: dadosMobile.pagina }) }).then((api) => {
    let arrayMobile = dadosMobile.produtos;
    let arrayApi = api.data.rows;
    let arrayResultado: any = arrayMobile.concat(arrayApi);
    setDadosMobile({ produtos: arrayResultado, pagina: dadosMobile.pagina + 1 > ultimaPagina ? ultimaPagina : dadosMobile.pagina + 1 });
  });
};

export const useListagemProdutos = () => {
  interface Dados {
    totalProdutos: number;
    ocultarPrecos: 0 | 1;
    produtos: { [key: number]: [] };
  }

  const [carregando, setCarregando] = useState(true);
  const [ordenacao, setOrdenacao] = useState({ sort: "nome", order: "asc", alterado: false });
  const [numeroPagina, setNumeroPagina] = useState(1);
  const [dados, setDados] = useState<Dados>({ totalProdutos: 0, produtos: { 1: [] }, ocultarPrecos: 0 });
  const eMobile = useMediaQuery({ maxWidth: 767 });
  const [dadosMobile, setDadosMobile] = useState({ produtos: [], pagina: 1 });
  const [setor, setSetor] = useState({ titulo: "Setor não encontrado", mensagem: "Nenhum produto encontrado para o filtro selecionado.", mensagem2: "", icone: faFaceFrown });
  const [exibirModalLogin, setExibirModalLogin] = useState(false);
  const { paramUrl1, paramUrl2 } = useParams();

  const filtrosConsulta = {
    ecm: 1,
    limit: 36,
    sort: ordenacao.sort,
    order: ordenacao.order,
    pagina: numeroPagina,
  };

  const ultimaPagina = Math.ceil(dados.totalProdutos / filtrosConsulta.limit);
  const qtdBotoesPaginas = gerarArrayPaginas(ultimaPagina);
  const dadosPaginacaoInicioFim = gerarPaginacaoLoop(numeroPagina, ultimaPagina);
  const arrayProdutosLoop = eMobile ? dadosMobile.produtos : dados.produtos[numeroPagina];

  useConsultar(setor, setDados, setCarregando, numeroPagina, ordenacao, filtrosConsulta, dados, paramUrl1, paramUrl2, setOrdenacao, setNumeroPagina, setDadosMobile, setSetor, setExibirModalLogin);

  return {
    dados,
    setor,
    eMobile,
    paramUrl1,
    ordenacao,
    carregando,
    dadosMobile,
    numeroPagina,
    ultimaPagina,
    filtrosConsulta,
    exibirModalLogin,
    qtdBotoesPaginas,
    arrayProdutosLoop,
    dadosPaginacaoInicioFim,
    setOrdenacao,
    setDadosMobile,
    setNumeroPagina,
    setExibirModalLogin,
  };
};
