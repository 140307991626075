import { jsonParse } from "components/funcoesGlobais";
import { useEffect, useState } from "react";
import api from "services/api";

export const useRodape = () => {
  const [empresa, setEmpresa] = useState(jsonParse("empresa"));

  useEffect(() => {
    if (!Object.entries(empresa).length) {
      api
        .get("empresa.php")
        .then((api) => {
          if (api.data.resultado) setEmpresa(api.data.empresa);
        })
        .catch((api) => console.error(api));
    }
    // eslint-disable-next-line
  }, []);

  return empresa;
};
