import axios from "axios";
import { jsonParse } from "components/funcoesGlobais";
import { useEffect } from "react";
import api from "services/api";

export const useConsultarUrls = (setDados: any, setCarregando: any) => {
  const usuario = jsonParse("usuario");
  const armazem_inicial_inicio = jsonParse("estoqueAtivo");

  useEffect(() => {
    //FUNÇÃO QUE SERÁ USADA PARA A CONSULTA DEPENDENDO DO CASO
    const consultar = (id_armazem: number) => {
      const links = [
        { url: "produtos.php", filtros: { ecm: 1, limit: 30, promocao: 1, armazem_inicial: id_armazem, cliente_prod_fav: usuario.cliente } },
        { url: "banners.php", filtros: { order: "asc", sort: "ordenacao" } },
        { url: "produtos_mais_vendidos.php", filtros: { limit: 30, armazem_inicial: id_armazem, cliente_prod_fav: usuario.cliente } },
        { url: "produtos.php", filtros: { ecm: 1, limit: 30, destaque: 1, armazem_inicial: id_armazem, cliente_prod_fav: usuario.cliente } },
        { url: "setores.php/produtos_setor", filtros: {} },
        { url: "empresa.php", filtros: {} },
      ];

      axios
        .all(links.map((rota) => api.get(rota.url, { params: rota.filtros })))
        .then((api) => {
          setDados({
            produtosPromo: api[0].data.rows,
            banners: api[1].data.rows,
            maisVendidos: api[2].data.rows,
            produtosDestaque: api[3].data.rows,
            produtosSetoresDestaque: api[4].data.rows,
            configEmpresa: api[5].data.empresa,
          });
          setCarregando(false);
        })
        .catch((e) => console.error(e));
    };

    //CASO O ARMZEM INICIAL JÁ ESTEJA SETADO, SE NÃO BUSCA O PADRÃO PRIMEIRO DEPOIS BUSCA OS PRODUTOS
    if (armazem_inicial_inicio.id) {
      consultar(armazem_inicial_inicio.id);
    } else {
      api.get("estoque_armazens.php", { params: { padrao: 1 } }).then((api) => consultar(api.data.rows[0].id));
    }
    // eslint-disable-next-line
  }, []);
};
