import { jsonParse } from "components/funcoesGlobais";
import api from "services/api";

export const verificarImagem = (url: string) => {
  const image = new Image();

  image.onerror = () => {
    const icone = document.querySelector(`img[src="${url}"]`) as HTMLImageElement;
    if (icone !== null) icone.src = "https://pgbr-img-site-ecommerce.nyc3.digitaloceanspaces.com/produto_misterioso.jpg";
  };
  image.src = url;
};

export const useCardProduto = (props: any) => {
  const { produto, className = "", pgFavorito } = props;
  const urlImagem = produto.imagem ? produto.imagem : "https://pgbr-img-site-ecommerce.nyc3.digitaloceanspaces.com/produto_misterioso.jpg";
  const empresa = jsonParse("empresa");
  const usuario = jsonParse("usuario");

  verificarImagem(urlImagem);

  return {
    produto,
    className,
    pgFavorito,
    urlImagem,
    empresa,
    usuario,
  };
};

export const adicionarRemoverFavorito = (id: number, usuario: any, pgFavorito?: boolean) => {
  const card = document.getElementById(id.toString());
  const badge = card?.querySelector(".badge-card.badge-favorito");
  if (!card || !badge) return;

  const classeFav = badge.classList.contains("favorito");
  const adicionarRemoverFav = classeFav
    ? api.delete(`produtos_favoritos.php`, { data: { cliente: usuario.cliente, produto: id } })
    : api.post("produtos_favoritos.php", { cliente: usuario.cliente, produto: id });

  adicionarRemoverFav.then((e) => {
    badge.classList[classeFav ? "remove" : "add"]("favorito");

    if (pgFavorito) {
      card.classList.add("fade");
      const campoQtd = document.querySelector(".total-ordenacao>span>strong");
      const campoProds = document.querySelector(".total-ordenacao>span>span");
      const qtdFavs = parseInt(campoQtd?.innerHTML.trim() as string);

      if (campoQtd) (campoQtd as HTMLElement).innerText = ` ${qtdFavs - 1 < 0 ? 0 : qtdFavs - 1} `;
      if (qtdFavs - 1 === 1 && campoProds) (campoProds as HTMLElement).innerText = "produto";

      setTimeout(() => {
        card.remove();
        if (document.querySelectorAll(".badge-favorito.favorito").length === 0) {
          document
            .getElementById("inicio-promocoes")
            ?.querySelectorAll("nav,.div-titulo,.total-ordenacao,.paginacao-produtos")
            .forEach((elemento) => elemento.remove());
          const mensagem = document.getElementById("mensagem-sem-produtos");
          mensagem?.classList.remove("d-none");
          mensagem?.classList.add("d-flex");
        }
      }, 300);
    }
  });
};
