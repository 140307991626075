import { useState } from "react";
import { exibirErroInput, jsonParse, limparValidacaoForm, redirecionar, setUsuarioConsulta } from "components/funcoesGlobais";
import api from "services/api";

export const rodarVerificacoes = async (useCadastro: number, exibirCadastro: any, ocultarCadastro: any, setMostrarAlerta: any, setMensagem: any, setVariante: any) => {
  const formulario = document.getElementById("entrar-cadastrar");

  if (!formulario) return;
  carregandoBtn(true);

  const dados = gerarDados(formulario);

  /* VALIDAR EMAIL */
  if (useCadastro === 0) {
    await api
      .get(`usuarios.php`, { params: dados })
      .then((api) => {
        if (api.status === 200) {
          parseInt(api.data.total) ? ocultarCadastro() : exibirCadastro();
        }
      })
      .catch((erros) => {
        console.error(erros);
      })
      .then(() => carregandoBtn(false));

    return;
  }

  /* CADASTRAR */
  if (useCadastro === 1) {
    api
      .post("usuarios.php", dados)
      .then((api) => {
        localStorage.setItem("token", api.data.usuario.token);
        sessionStorage.setItem("alerta", JSON.stringify({ mensagem: "Em instantes você receberá um email de confirmação no seu email.", variante: "success" }));
        redirecionar("");
      })
      .catch((api) => {
        const inputEmail = document.querySelector("input[name=email]") as HTMLInputElement;
        ocultarCadastro();
        if (inputEmail) exibirErroInput(inputEmail, api.response.data.mensagem);
      })
      .then(() => carregandoBtn(false));
    return;
  }

  /* LOGAR */
  if (useCadastro === 2) {
    await api
      .get(`usuarios.php/entrar`, { params: dados })
      .then((api) => {
        if (api.data.resultado) {
          setUsuarioConsulta(api.data.usuario);
          localStorage.setItem("token", api.data.token_acesso);
          redirecionar("");
          return;
        }
        setMensagem("Email e/ou senha incorreto");
        setVariante("warning");
        setMostrarAlerta(true);
      })
      .catch((api) => {
        console.error(api);
      })
      .then(() => carregandoBtn(false));
    return;
  }
};

export const useExibirCadastro = () => {
  const [useCadastro, setCadastro]: any = useState(0);

  const exibirCadastro = () => {
    limparValidacaoForm("entrar-cadastrar");
    const titulo = document.querySelector(".titulo");
    document.getElementById("div-cadastrese")?.classList.add("d-none");
    document.getElementById("div-entrar")?.classList.remove("d-none");
    titulo && (titulo.innerHTML = "Cadastre sua conta");

    setCadastro(1);
  };

  const ocultarCadastro = () => {
    const titulo = document.querySelector(".titulo");
    limparValidacaoForm("entrar-cadastrar");

    document.getElementById("div-cadastrese")?.classList.remove("d-none");
    document.getElementById("div-entrar")?.classList.add("d-none");

    titulo && (titulo.innerHTML = "Entre com sua conta");
    setCadastro(2);
  };

  return { useCadastro, exibirCadastro, ocultarCadastro };
};

export const exibirModal = () => {
  const input = document.querySelector("input[name=email]") as HTMLInputElement;
  const body = document.getElementById("modal-body");

  if (body !== null) {
    body.innerHTML = body.innerHTML.replace("$email", input.value);
  }
};

export const clickEnviarToken = (
  setExibirModal: any,
  funcDefBackDrop: any,
  bodyModal: number,
  exibirCadastro: any,
  setBodyModal: any,
  setMostrarAlerta: any,
  setMensagem: any,
  setVariante: any,
  btn?: any
) => {
  const inputEmail = document.querySelector("input[name=email]") as HTMLInputElement;
  btn.classList.add("disabled");
  funcDefBackDrop("static");

  if (bodyModal === 1) {
    //ENVIAR NOVO TOKEN
    api
      .post("redefinicao_senha.php", { email: inputEmail.value })
      .then((api) => {
        funcDefBackDrop(undefined);
        btn.classList.remove("disabled");

        if (api.data.resultado) {
          setExibirModal(true);
          setBodyModal(2);
        }
      })
      .catch((api) => {
        setExibirModal(false);
        setVariante("danger");
        setMensagem(api.response.data.mensagem);
        setMostrarAlerta(true);
      });
  } else if (bodyModal === 2) {
    //VALIDAR TOKEN
    const inputToken = document.querySelector("input[name=token]") as HTMLInputElement;
    const inputSenha = document.querySelector("input[name=senha_nova]") as HTMLInputElement;

    api
      .put("redefinicao_senha.php", {
        codigo: inputToken.value,
        email: inputEmail.value,
        data_expiracao_codigo: true,
        senha_nova: inputSenha.value,
      })
      .then((api) => {
        funcDefBackDrop(undefined);
        btn.classList.remove("disabled");

        if (api.data.resultado) {
          setExibirModal(false);
          setBodyModal(1);

          setVariante("success");
          setMensagem(api.data.mensagem);
          setMostrarAlerta(true);
        }
      })
      .catch((api) => {
        if (api.response.status === 404) {
          setExibirModal(false);
          exibirCadastro();
          setVariante("danger");
          setMensagem(api.response.data.mensagem);
          setMostrarAlerta(true);
        } else {
          exibirErroInput(inputToken, api.response.data.mensagem);
        }
      });
  }
};

export const validarTokenCaptcha = async (token: string | null, setValidoRecaptcha: any) => {
  if (token === null) return setValidoRecaptcha(false);

  await api
    .get("recaptcha.php/validarCaptcha", {
      params: {
        secret: process.env.REACT_APP_SECRET_CAPTCHA,
        response: token,
      },
    })
    .then((consulta) => {
      setValidoRecaptcha(consulta.data.success);
    })
    .catch((consulta) => {
      setValidoRecaptcha(false);
      console.error(consulta);
    });

  // await axios
  //   .get("https://www.google.com/recaptcha/api/siteverify", {
  //     params: {
  //       secret: process.env.REACT_APP_SECRET_CAPTCHA,
  //       response: token,
  //     },
  //   })
  //   .then((consulta) => {
  // setValidoRecaptcha(consulta.data.success);
  //     }
  //   })
  //   .catch((consulta) => {
  //     setValidoRecaptcha(false);
  //     console.error(consulta);
  //   });
};

/* FUNÇÕES USADAS SOMENTE NO ARQUIVO */
function gerarDados(formulario: HTMLElement) {
  const inputs = formulario.querySelectorAll("input");
  let dados: Record<string, string> = {};

  inputs.forEach((input) => {
    if (input.name) {
      dados[input.name] = input.value;
    }
  });

  return dados;
}

const carregandoBtn = (carregando: boolean) => {
  const btn = document.querySelector("#continuar");
  const span = btn?.querySelector("span");
  const spin = btn?.querySelector("svg");
  if (!btn || !spin || !span) return;

  if (carregando) {
    spin.classList.remove("d-none");
    btn.classList.add("disabled");
  } else {
    spin.classList.add("d-none");
    btn.classList.remove("disabled");
  }
  span.textContent = carregando ? "Aguarde" : "Continuar";
};

export const useEntrarCadastrar = (trigger: any) => {
  const usuario = jsonParse("usuario");
  if (usuario.logado) redirecionar("/");

  const [validoRecaptcha, setValidoRecaptcha] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const [variante, setVariante] = useState("success");
  const [modalBody, setModalBody] = useState(1);
  const [backdrop, setBackDrop] = useState(undefined);
  const [tel, setTel] = useState(0);
  const [exibir, setExibir] = useState(false);
  const [useCadastro, setCadastro]: any = useState(0);

  const exibirCadastro = () => {
    limparValidacaoForm("entrar-cadastrar");
    const titulo = document.querySelector(".titulo");
    document.getElementById("div-cadastrese")?.classList.add("d-none");
    document.getElementById("div-entrar")?.classList.remove("d-none");
    titulo && (titulo.innerHTML = "Cadastre sua conta");

    setCadastro(1);
  };

  const ocultarCadastro = () => {
    const titulo = document.querySelector(".titulo");
    limparValidacaoForm("entrar-cadastrar");

    document.getElementById("div-cadastrese")?.classList.remove("d-none");
    document.getElementById("div-entrar")?.classList.add("d-none");

    titulo && (titulo.innerHTML = "Entre com sua conta");
    setCadastro(2);
  };

  const onVerificar = (token: string) => {
    if (!validoRecaptcha) validarTokenCaptcha(token, setValidoRecaptcha);
  };

  const validar = async (e: any) => {
    e.preventDefault();
    if (!(await trigger())) return false;
    rodarVerificacoes(useCadastro, exibirCadastro, ocultarCadastro, setMostrarAlerta, setMensagem, setVariante);
  };

  const controladorChange = async (e: any) => {
    const input = e.target;

    if (input.name === "telefone") setTel(input.value.replace(/\s|\(|\)|_|-/g, "").length);
    if (!(await trigger(input.name))) return false;
    input.classList.add("is-valid");
  };

  const enviarToken = async (e: any) => {
    if (!(await trigger(["token", "senha_nova", "confirmar_senha"]))) return;

    clickEnviarToken(setExibir, setBackDrop, modalBody, exibirCadastro, setModalBody, setMostrarAlerta, setMensagem, setVariante, e.target);
  };

  return {
    tel,
    exibir,
    variante,
    mensagem,
    backdrop,
    modalBody,
    mostrarAlerta,
    validoRecaptcha,
    useCadastro,
    exibirCadastro,
    ocultarCadastro,
    setExibir,
    setMostrarAlerta,
    onVerificar,
    validar,
    setModalBody,
    controladorChange,
    enviarToken,
  };
};
