import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alerta } from "components/common/alerta/Alerta";
import { Button, FloatingLabel, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { clickCancelarEdicaoInfos, clickSalvar, enviarEmailVerificacao, usePerfil } from "./usePerfil";
import "bootstrap/js/dist/tooltip";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FormInputs } from "./interfaceFormPerfil";

const AbaPerfil = () => {
  const {
    register,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormInputs>();

  const { tel, alerta, usuario, emEdicao, carregando, emailEnviado, mostrarAlerta, keyUp, setAlerta, setEmEdicao, setEmailEnviado, setMostrarAlerta } = usePerfil(setValue, trigger);

  return (
    <>
      {mostrarAlerta && <Alerta mensagem={alerta.mensagem} variante={alerta.variante} setFechar={setMostrarAlerta} />}
      <div className="containers p-3 containers-abas">
        <div id="corpo-meu-perfil">
          <div className="d-flex justify-content-between">
            <span className="fs-5 titulos">
              <strong>Meu Perfil</strong>
            </span>
            <div>
              <Button variant="secondary">Alterar senha</Button>
              <Button variant="primary" className="ms-1" onClick={() => setEmEdicao(!emEdicao)}>
                Editar informações
              </Button>
            </div>
          </div>
          <div className="formulario mt-3 border-top pt-4">
            <div className="row">
              <div className="col-12 col-md-6">
                <FloatingLabel controlId="float-nome" label="Nome:" className={`mb-2 ${carregando ? "carregando" : ""}`}>
                  <Form.Control
                    disabled={!emEdicao}
                    type="text"
                    placeholder="Nome:"
                    className={`${errors.nome && "is-invalid"}`}
                    onKeyUp={keyUp}
                    {...register("nome", {
                      required: "O campo nome é obrigatório",
                      minLength: {
                        value: 3,
                        message: "O campo deve conter no mínimo 3 caracteres",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.nome?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-md-6 position-relative">
                {carregando === false && (
                  <OverlayTrigger
                    key={"top"}
                    placement="top"
                    overlay={
                      <Tooltip>
                        {parseInt(usuario.email_verificado) === 1 ? "E-mail verificado" : "E-mail não verificado, para verifica-lo basta clicar no ícone que um e-mail de verificação será enviado"}
                      </Tooltip>
                    }
                  >
                    <span
                      className={`${parseInt(usuario.email_verificado) === 1 ? "text-success ja-verificado" : "text-danger"} span-verificado`}
                      id="span-vericado"
                      onClick={() => enviarEmailVerificacao(usuario, emailEnviado, setEmailEnviado, setAlerta, setMostrarAlerta)}
                    >
                      <FontAwesomeIcon icon={parseInt(usuario.email_verificado) === 1 ? faCheckCircle : faExclamationCircle} />
                    </span>
                  </OverlayTrigger>
                )}

                <FloatingLabel controlId="float-email" label={`Email:`} className={`mb-2 ${carregando ? "carregando" : ""} div-email`}>
                  <Form.Control
                    disabled={!emEdicao}
                    type="email"
                    className={`${errors.email && "is-invalid"}`}
                    placeholder="Email:"
                    {...register("email", {
                      required: "O campo email é obrigatório",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Email inválido",
                      },
                    })}
                    onKeyUp={keyUp}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-md-6">
                <FloatingLabel controlId="float-cpf" label="CPF:" className={`mb-2 ${carregando ? "carregando" : ""}`}>
                  <ReactInputMask
                    disabled={!emEdicao}
                    mask={"999.999.999-99"}
                    placeholder="CPF"
                    maskChar={""}
                    className={`${errors.cpf && "is-invalid"} form-control`}
                    onKeyUp={keyUp}
                    {...register("cpf", {
                      required: "O campo CPF é obrigatório",
                      minLength: {
                        value: 14,
                        message: "O campo deve conter 14 caracteres",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.cpf?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-md-6">
                <FloatingLabel controlId="floatingSelect" label="Gênero" className={`mb-2 ${carregando ? "carregando" : ""}`} onChange={keyUp}>
                  <Form.Select
                    disabled={!emEdicao}
                    id="select-genero"
                    aria-label="Floating label"
                    {...register("genero", { required: "O campo gênero é obrigatório" })}
                    className={`${errors.genero && "is-invalid"}`}
                  >
                    <option>Selecione</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="O">Outro</option>
                    <option value="I">Prefiro não identificar</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.genero?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-md-6">
                <FloatingLabel controlId="float-nascimento" label="Nascimento:" className={`mb-2 ${carregando ? "carregando" : ""}`}>
                  <Form.Control
                    disabled={!emEdicao}
                    type="date"
                    className={`${errors.data_nascimento && "is-invalid"}`}
                    placeholder="Email:"
                    {...register("data_nascimento", {
                      minLength: {
                        value: 10,
                        message: "O campo deve conter no mínimo 10 caracteres",
                      },
                      required: "O campo nascimento é obrigatório",
                    })}
                    onKeyUp={keyUp}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.data_nascimento?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-md-6">
                <FloatingLabel controlId="float-telefone" label="Telefone:" className={`mb-2 ${carregando ? "carregando" : ""}`}>
                  <ReactInputMask
                    disabled={!emEdicao}
                    mask={tel > 10 ? "(99) 99999-9999" : "(99) 9999-99999"}
                    placeholder="Telefone"
                    maskChar={""}
                    className={`${errors.telefone && "is-invalid"} form-control`}
                    onKeyUp={keyUp}
                    {...register("telefone", {
                      minLength: {
                        value: 14,
                        message: "O campo deve conter no mínimo 14 caracteres",
                      },
                      required: "O campo telefone é obrigatório",
                    })}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.telefone?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <div className="salvar-cancelar">
              {emEdicao && (
                <>
                  <Button variant="danger" onClick={() => clickCancelarEdicaoInfos(usuario, setEmEdicao)}>
                    Cancelar
                  </Button>
                  <Button variant="success" onClick={() => clickSalvar(usuario.id, trigger, getValues, setEmEdicao, setMostrarAlerta, setAlerta)}>
                    Salvar
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AbaPerfil };
