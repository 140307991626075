import * as ReactBt from "react-bootstrap";
import { SessaoCarousel } from "components/sessaoCarousel/SessaoCarousel";
import { BannerVertical } from "components/bannerVertical/BannerVertical";
import { CardProduto } from "components/cardProduto/CardProduto";
import { Cabecalho } from "components/cabecalho/Cabecalho";
import { Rodape } from "components/rodape/Rodape";
import { CategoriaDestaque } from "components/categoriaDestaque/CategoriaDestaque";
import { useState } from "react";
import { CarregandoBanner } from "components/common/carregando/CarregandoBanner";
import { CarregandoDestaques } from "components/common/carregando/CarregandoDestaques";
import { CarregandoCards } from "components/common/carregando/CarregandoCards";
import { useConsultarUrls } from "./useInicio";
import "./inicio.css";

const Inicio = () => {
  const [dados, setDados] = useState({ produtosPromo: [], banners: [], maisVendidos: [], produtosDestaque: [], produtosSetoresDestaque: [], configEmpresa: { ocultar_precos: 0 } });
  const [carregando, setCarregando] = useState(true);
  useConsultarUrls(setDados, setCarregando);

  return (
    <>
      <Cabecalho />
      <ReactBt.Container className="pt-4">
        <ReactBt.Carousel variant="dark">
          {carregando && <CarregandoBanner />}
          {!carregando &&
            dados.banners.length > 0 &&
            dados.banners.map((banner: any, index: any) => (
              <ReactBt.CarouselItem key={index}>
                <div
                  key={banner.id}
                  style={{
                    backgroundImage: `url(${banner.desktop})`,
                    height: "300px",
                    width: "100%",
                    backgroundSize: "cover",
                  }}
                />
              </ReactBt.CarouselItem>
            ))}
        </ReactBt.Carousel>
        {carregando && <CarregandoDestaques setores={7} />}
        {!carregando && dados.produtosSetoresDestaque.length > 0 && (
          <div id="categorias-destaque">
            {dados.produtosSetoresDestaque.map((destaque: any, idx: number) => (
              <CategoriaDestaque texto={destaque.descricao} icone={destaque.icone} key={idx} href={`produtos/${destaque.id}`}></CategoriaDestaque>
            ))}
          </div>
        )}
        <>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h3>Ofertas</h3>
            <a href="/produtos/ofertas" className="btn btn-primary">
              VER MAIS
            </a>
          </div>
          <SessaoCarousel>
            {carregando && <CarregandoCards cards={6} />}
            {!carregando &&
              dados.produtosPromo.length > 0 &&
              dados.produtosPromo.map((produto: any, index: number) => <CardProduto produto={produto} key={index} ocultar_precos={dados.configEmpresa.ocultar_precos} />)}
          </SessaoCarousel>
        </>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-5">
          <h3>Mais vendidos</h3>
        </div>
        {dados.maisVendidos.length > 0 && (
          <SessaoCarousel>
            {carregando && <CarregandoCards cards={6} />}
            {!carregando && dados.maisVendidos.map((produto: any, index: number) => <CardProduto produto={produto} key={index} ocultar_precos={dados.configEmpresa.ocultar_precos} />)}
          </SessaoCarousel>
        )}
        <div className="pt-5 d-flex flex-wrap gap-3 justify-content-between align-items-center">
          <BannerVertical></BannerVertical>
          <BannerVertical></BannerVertical>
          <BannerVertical></BannerVertical>
        </div>
        {dados.produtosDestaque.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-1 mt-5">
              <h3>Destaques</h3>
              <a href="/produtos/destaques" className="btn btn-primary">
                VER MAIS
              </a>
            </div>
            <SessaoCarousel>
              {dados.produtosDestaque.map((produto: any, index: number) => (
                <CardProduto produto={produto} key={index} ocultar_precos={dados.configEmpresa.ocultar_precos} />
              ))}
            </SessaoCarousel>
          </>
        )}
        {dados.produtosSetoresDestaque.length > 0 &&
          dados.produtosSetoresDestaque.map((setor: { descricao: string; produtos: any; id: number }, index: number) => {
            return (
              JSON.parse(setor.produtos).length && (
                <div key={index}>
                  <div className="d-flex justify-content-between align-items-center mb-1 mt-5" key={`setor_${setor.id}`}>
                    <h3>{setor.descricao.toLocaleUpperCase("pt-br")}</h3>
                    <a href={`/produtos/${setor.id}`} className="btn btn-primary">
                      VER MAIS
                    </a>
                  </div>
                  <SessaoCarousel>
                    {JSON.parse(setor.produtos)
                      .splice(0, 30)
                      .map((produto: any) => (
                        <CardProduto produto={produto} key={produto.id} ocultar_precos={dados.configEmpresa.ocultar_precos} />
                      ))}
                  </SessaoCarousel>
                </div>
              )
            );
          })}
      </ReactBt.Container>
      <Rodape />
    </>
  );
};

export { Inicio };
