import { siteUrl } from "components/funcoesGlobais";
import { Button, Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import ReactInputMask from "react-input-mask";
import "./entrarCadastrar.css";
import { exibirModal, useEntrarCadastrar } from "./useEntrarCadastrar";
import { useForm } from "react-hook-form";
import { Alerta } from "components/common/alerta/Alerta";
import { FormInputs } from "./entrarCadastrar.interface";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const EntrarCadastrar = () => {
  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm<FormInputs>();

  const {
    tel,
    exibir,
    variante,
    mensagem,
    backdrop,
    modalBody,
    mostrarAlerta,
    validoRecaptcha,
    useCadastro,
    exibirCadastro,
    ocultarCadastro,
    setExibir,
    setMostrarAlerta,
    onVerificar,
    setModalBody,
    validar,
    controladorChange,
    enviarToken,
  } = useEntrarCadastrar(trigger);

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY_RECAPTCHA ?? ""}>
        <GoogleReCaptcha onVerify={onVerificar} />
        {mostrarAlerta && <Alerta mensagem={mensagem} variante={variante} setFechar={setMostrarAlerta} />}
        <Container className="container-login mt-5">
          <div className="logo">
            <a href={"/"}>
              <img src={siteUrl("imgs/logos/logo_progen_512.png", process.env.REACT_APP_SITE_URL)} alt="Logo marca" />
            </a>
          </div>
          <div className="formulario">
            <h5 className="titulo mb-4">Entre ou Cadastre-se</h5>
            <Form id="entrar-cadastrar" onSubmit={validar}>
              <FloatingLabel controlId="float-email" label="Informe o seu e-mail:" className="mb-2">
                <Form.Control
                  type="email"
                  placeholder="Informe o seu e-mail:"
                  {...register("email", {
                    required: "O campo email é obrigatório.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email inválido",
                    },
                  })}
                  className={errors.email ? " is-invalid" : ""}
                  onKeyUp={controladorChange}
                />
                <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
              {useCadastro === 2 && (
                <>
                  <FloatingLabel controlId="float-senha" label="Senha:">
                    <Form.Control type="password" placeholder="Senha:" {...register("senha")} className={errors.senha ? " is-invalid" : ""} onKeyUp={controladorChange} />
                    <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                      {errors.senha?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <div className="lh-1 fs-6 mb-2 mt-0" id="esqueci-senha">
                    <button className="ps-1 btn-limpo text-primary" onClick={() => setExibir(true)} type="button">
                      Esqueci minha senha
                    </button>
                  </div>
                </>
              )}
              {useCadastro === 1 && (
                <>
                  <FloatingLabel controlId="float-senha" label="Senha:" className="mb-2">
                    <Form.Control
                      type="password"
                      placeholder="Senha:"
                      {...register("senha", {
                        required: "O campo senha é obrigatório",
                        minLength: {
                          value: 6,
                          message: "O campo deve conter pelo menos 6 caracteres",
                        },
                      })}
                      className={errors.senha ? " is-invalid" : ""}
                      onKeyUp={controladorChange}
                    />
                    <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                      {errors.senha?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="float-nome" label="Nome:" className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Nome:"
                      {...register("nome", {
                        required: "O campo nome é obrigatório",
                        minLength: {
                          value: 3,
                          message: "O campo deve conter no mínimo 3 caracteres",
                        },
                      })}
                      className={errors.nome ? " is-invalid" : ""}
                      onKeyUp={controladorChange}
                    />
                    <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                      {errors.nome?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel controlId="float-telefone" label="Telefone:" className="mb-2">
                    <ReactInputMask
                      mask={tel > 10 ? "(99) 99999-9999" : "(99) 9999-99999"}
                      placeholder="Telefone"
                      maskChar={""}
                      onKeyUp={controladorChange}
                      {...register("telefone", {
                        required: "O campo telefone é obrigatório",
                        validate: {
                          telefone: (telefone) => {
                            const limpo = telefone.replace(/\s|\(|\)|_|-/g, "");
                            const fixoMovel = limpo.length === 11 || limpo.length === 10;
                            return fixoMovel || "Telefone inválido";
                          },
                        },
                      })}
                      className={`form-control ${errors.telefone ? " is-invalid" : ""}`}
                    />
                    <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                      {errors.telefone?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <div className="texto-button lh-1 pb-2">
                    Ao continuar com o cadastro você concorda com a nossa
                    <a href="/entrar" className="ps-1">
                      Política de Privacidade e segurança
                    </a>
                  </div>
                </>
              )}
              <Button id="continuar" type="submit" variant="primary" className={`w-100 mt-1 ${!validoRecaptcha && "disabled"}`}>
                <FontAwesomeIcon icon={faCircleNotch} spin={true} className={`me-1 ${validoRecaptcha ? "d-none" : ""}`} id="icone-spin" />
                <span>{validoRecaptcha ? "Continuar" : "Aguarde"}</span>
              </Button>
            </Form>
            <div className="text-center texto-button d-none" id="div-cadastrese">
              Não tem cadastro?
              <button id="btn-cadastrar" onClick={exibirCadastro} className="ps-1 limpar-btn">
                Cadastre-se
              </button>
            </div>
            <div className="texto-button d-none text-center" id="div-entrar">
              Já possui uma conta?
              <button id="btn-entrar" onClick={ocultarCadastro} className="ps-1 limpar-btn">
                Entrar
              </button>
            </div>
            <div className="border-top mt-3 pt-3 direitos text-center">© 2023 ProgenBR - Todos direitos reservados</div>
          </div>
        </Container>

        <Modal show={exibir} centered keyboard={true} onHide={() => [setExibir(false), setModalBody(1)]} onShow={exibirModal} backdrop={backdrop}>
          <Modal.Header closeButton>
            <Modal.Title>Esqueceu sua senha?</Modal.Title>
          </Modal.Header>
          {modalBody === 1 && (
            <>
              <Modal.Body id="modal-body">
                Você pode recuperar sua senha através do seu e-mail <strong>$email</strong>, para poder redefinir sua senha clique no botão abaixo para enviar o e-mail com código que você usará para
                redefinir sua senha.
                <div className="pt-3">Caso seu email esteja incorreto corrija no formulário.</div>
              </Modal.Body>
              <button type="button" onClick={() => setModalBody(2)} className="pt-2 limpar-btn">
                Já tenho um código.
              </button>
            </>
          )}
          {modalBody === 2 && (
            <Modal.Body id="modal-body">
              <FloatingLabel controlId="float-token" label="Digite seu código:" className="mb-2">
                <Form.Control
                  placeholder="Digite seu código:"
                  onKeyUp={controladorChange}
                  type="number"
                  {...register("token", {
                    required: "O campo código é obrigatório",
                    min: { value: 100000, message: "O código deve conter no mínimo 6 caracteres" },
                    max: { value: 999999, message: "O código deve conter no máximo 6 caracteres" },
                  })}
                  className={`form-control ${errors.token ? " is-invalid" : ""}`}
                />
                <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                  {errors.token?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="float-senha-nova" label="Digite sua nova senha:" className="mb-2">
                <Form.Control
                  placeholder="Digite sua nova senha:"
                  onKeyUp={controladorChange}
                  type="password"
                  {...register("senha_nova", {
                    required: "O campo senha é obrigatório",
                    minLength: { value: 6, message: "O deve conter pelo menos 6 caracteres." },
                  })}
                  className={`form-control ${errors.senha_nova ? " is-invalid" : ""}`}
                />
                <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                  {errors.senha_nova?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
              <FloatingLabel controlId="float-senha-repetir" label="Digite a senha novamente:" className="mb-2">
                <Form.Control
                  placeholder="Digite a senha novamente:"
                  onKeyUp={controladorChange}
                  type="password"
                  {...register("confirmar_senha", {
                    required: "O campo confirmar senha é obrigatório",
                    minLength: { value: 6, message: "O deve conter pelo menos 6 caracteres." },
                    validate: {
                      isEqual: (value) => {
                        const senha_nova = watch("senha_nova");
                        return value === senha_nova || "As senhas não coincidem";
                      },
                    },
                  })}
                  className={`form-control ${errors.confirmar_senha ? " is-invalid" : ""}`}
                />
                <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                  {errors.confirmar_senha?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="primary" className="w-100" onClick={enviarToken}>
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      </GoogleReCaptchaProvider>
    </>
  );
};

export { EntrarCadastrar };
