import { faArrowDown19, faArrowDownAZ, faArrowUp19, faArrowUpAZ, faAward, faChevronRight, faPercent } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Cabecalho } from "components/cabecalho/Cabecalho";
import { CardProduto } from "components/cardProduto/CardProduto";
import { CarregandoCards } from "components/common/carregando/CarregandoCards";
import { Rodape } from "components/rodape/Rodape";
import { Breadcrumb, Button, Container, Dropdown, Modal, Pagination } from "react-bootstrap";
import { consultarApiMobile, ordemDropdown, useListagemProdutos } from "./useListagemProdutos";
import "./listagemProdutos.css";
import { redirecionar } from "components/funcoesGlobais";

const ListagemProdutos = () => {
  const {
    dados,
    setor,
    eMobile,
    paramUrl1,
    ordenacao,
    carregando,
    dadosMobile,
    numeroPagina,
    ultimaPagina,
    filtrosConsulta,
    exibirModalLogin,
    qtdBotoesPaginas,
    arrayProdutosLoop,
    dadosPaginacaoInicioFim,
    setOrdenacao,
    setDadosMobile,
    setNumeroPagina,
    setExibirModalLogin,
  } = useListagemProdutos();

  return (
    <>
      <Cabecalho />
      <Container className="mt-4" id="inicio-promocoes">
        {(carregando || (dados.produtos[numeroPagina] && arrayProdutosLoop.length > 0)) && (
          <>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
              <Breadcrumb.Item active>
                <FontAwesomeIcon icon={faChevronRight} /> <span className={`nome-setor ${carregando ? "carregando" : ""}`}>{setor.titulo}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="div-titulo mb-4">
              <h3>
                <span className={`nome-setor ${carregando ? "carregando" : ""}`}>{setor.titulo}</span>
              </h3>
            </div>
            <div className="total-ordenacao my-2">
              <span>
                <strong> {dados.totalProdutos} </strong> <span>{dados.totalProdutos === 1 ? "produto" : "produtos"}</span>
              </span>
              <div className="div-ordenar">
                <span>Ordenar por:</span>
                <Dropdown className="ms-1">
                  <Dropdown.Toggle id="btn-dropdown">
                    <FontAwesomeIcon className="pe-1" icon={ordemDropdown[ordenacao.sort][ordenacao.order].icone} /> {ordemDropdown[ordenacao.sort][ordenacao.order].descricao}
                  </Dropdown.Toggle>
                  <Dropdown.Menu rootCloseEvent="click" className="dropdown-menu">
                    <button className="dropdown-item" onClick={() => setOrdenacao({ sort: "vendidos", order: "desc", alterado: true })}>
                      <FontAwesomeIcon icon={faAward} /> Mais vendidos
                    </button>
                    <button className="dropdown-item border-top" onClick={() => setOrdenacao({ sort: "desconto", order: "desc", alterado: true })}>
                      <FontAwesomeIcon icon={faPercent} /> Maiores descontos
                    </button>
                    <button className="dropdown-item border-top" onClick={() => setOrdenacao({ sort: "valor", order: "asc", alterado: true })}>
                      <FontAwesomeIcon icon={faArrowDown19} /> Menor preço
                    </button>
                    <button className="dropdown-item border-top" onClick={() => setOrdenacao({ sort: "valor", order: "desc", alterado: true })}>
                      <FontAwesomeIcon icon={faArrowUp19} /> Maior preço
                    </button>
                    <button className="dropdown-item border-top" onClick={() => setOrdenacao({ sort: "nome", order: "asc", alterado: true })}>
                      <FontAwesomeIcon icon={faArrowDownAZ} /> Nome
                    </button>
                    <button className="dropdown-item border-top" onClick={() => setOrdenacao({ sort: "nome", order: "desc", alterado: true })}>
                      <FontAwesomeIcon icon={faArrowUpAZ} /> Nome
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </>
        )}
        <div className="produtos-promocao" id="container-cards">
          <>
            {carregando && <CarregandoCards cards={18} />}
            {!carregando &&
              dados.produtos[numeroPagina] !== undefined &&
              arrayProdutosLoop.length > 0 &&
              arrayProdutosLoop.map((produto: any, idx: number) => (
                <CardProduto produto={produto} ocultar_precos={dados.ocultarPrecos} key={idx} pgFavorito={typeof paramUrl1 === "string" && paramUrl1.toLocaleLowerCase().trim() === "meus-favoritos"} />
              ))}
            <div className={`${arrayProdutosLoop && arrayProdutosLoop.length === 0 ? "d-flex" : "d-none"} flex-column`} id="mensagem-sem-produtos">
              <FontAwesomeIcon icon={setor.icone} size="6x" />
              <span className="fs-3 text-center">{setor.mensagem}</span>
              <span className="fs-5 lh-1 text-center">{setor.mensagem2}</span>
            </div>
          </>
        </div>
        {dados.produtos[numeroPagina] !== undefined && arrayProdutosLoop.length > 0 && (
          <div className="paginacao-produtos">
            {eMobile && dadosMobile.pagina < ultimaPagina && <Button onClick={() => consultarApiMobile(filtrosConsulta, dadosMobile, setDadosMobile, ultimaPagina)}>Ver mais</Button>}
            {!eMobile && (
              <Pagination>
                <Pagination.Prev onClick={() => setNumeroPagina(numeroPagina - 1 >= 1 ? numeroPagina - 1 : 1)} disabled={numeroPagina === 1} />
                <Pagination.Item onClick={() => setNumeroPagina(1)} active={numeroPagina === 1}>
                  1
                </Pagination.Item>
                {numeroPagina > 5 && ultimaPagina > 9 && <Pagination.Ellipsis />}
                {qtdBotoesPaginas.map((pagina) => {
                  if (ultimaPagina < 9 && pagina + 1 !== 1 && pagina + 1 !== ultimaPagina) {
                    return (
                      <Pagination.Item onClick={() => setNumeroPagina(pagina + 1)} active={numeroPagina === pagina + 1} key={pagina}>
                        {pagina + 1}
                      </Pagination.Item>
                    );
                  }
                  if (pagina + 1 >= dadosPaginacaoInicioFim.inicio && pagina + 1 <= dadosPaginacaoInicioFim.fim) {
                    return (
                      <Pagination.Item onClick={() => setNumeroPagina(pagina + 1)} active={numeroPagina === pagina + 1} key={pagina}>
                        {pagina + 1}
                      </Pagination.Item>
                    );
                  }

                  return <div key={pagina} />;
                })}
                {ultimaPagina > 9 && numeroPagina <= ultimaPagina - 5 && <Pagination.Ellipsis />}
                {qtdBotoesPaginas.length > 1 && (
                  <Pagination.Item active={numeroPagina === qtdBotoesPaginas[qtdBotoesPaginas.length - 1] + 1} onClick={() => setNumeroPagina(qtdBotoesPaginas[qtdBotoesPaginas.length - 1] + 1)}>
                    {qtdBotoesPaginas[qtdBotoesPaginas.length - 1] + 1}
                  </Pagination.Item>
                )}
                <Pagination.Next onClick={() => setNumeroPagina(numeroPagina + 1 <= ultimaPagina ? numeroPagina + 1 : ultimaPagina)} disabled={numeroPagina === ultimaPagina} />
              </Pagination>
            )}
          </div>
        )}
      </Container>
      <Rodape />

      <Modal show={exibirModalLogin} backdrop="static" keyboard={false} onHide={() => setExibirModalLogin(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>Para acessar seus produtos favoritos é necessário estar logado.</Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => redirecionar("/")}>
            Voltar ao inicio
          </Button>
          <Button variant="primary" onClick={() => redirecionar("/entrar")}>
            Ir para login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { ListagemProdutos };
