import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { AppRoutes } from "utils/routes";
import { ModalCookies } from "components/modalCookies/ModalCookies";
import { UsuarioProvider } from "context/UsuarioContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const cookies = localStorage.cookiesAceitos === undefined || localStorage.cookiesAceitos !== "aceitos";
//teste
root.render(
  <>
    <UsuarioProvider>
      <AppRoutes />
      {cookies && <ModalCookies />}
    </UsuarioProvider>
  </>
);
