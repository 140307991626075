import { redirecionar } from "components/funcoesGlobais";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "services/api";

export const useVerificarEmail = () => {
  const parametrosUrl = useParams();
  const email = parametrosUrl.email !== undefined ? window.atob(parametrosUrl.email) : "";

  useEffect(() => {
    const span = document.getElementById("span-verificando") as HTMLSpanElement;

    if (email === "") {
      span.classList.remove("verificando");
      span.innerText = "Algo deu errado com o seu link, solicite um novo.";
      return;
    }

    api
      .put("usuarios.php/verificar_email", { email: email })
      .then(() => {
        span.innerText = "E-mail verificado com sucesso, você será redirecionado(a).";
      })
      .catch((erros) => {
        console.error(erros);
        span.innerText = erros.response.data.mensagem + ", você será redirecionado(a).";
      })
      .then(() => {
        span.classList.remove("verificando");
        setTimeout(() => redirecionar(""), 3000);
      });
    // eslint-disable-next-line
  }, []);
};
