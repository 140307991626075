import "./sessaoCarousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import * as icones from "@fortawesome/free-solid-svg-icons";
import { clickAvancar, clickVoltar } from "./useSessaoCarousel";

const SessaoCarousel: React.FC<{ className?: string; children?: ReactNode; comBorda?: boolean }> = (props) => {
  const { className = "", children, comBorda = true } = props;

  return (
    <div className={`container-carousel position-relative ${className} ${comBorda ? "borda-corousel" : ""}`}>
      <div className="carousel-sessao">{children}</div>
      <button className="btn btn-primary rounded-circle item-anterior-carousel botoes-carousel esquerda" type="button" onClick={clickVoltar}>
        <FontAwesomeIcon icon={icones.faChevronLeft} />
      </button>
      <button className="btn btn-primary rounded-circle proximo-item-carousel botoes-carousel direita" type="button" onClick={clickAvancar}>
        <FontAwesomeIcon icon={icones.faChevronRight} />
      </button>
    </div>
  );
};

export { SessaoCarousel };
