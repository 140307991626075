import { CardLoading } from "./CardLoading";
import "./carregando.css";

interface Props {
  cards?: number;
}

const CarregandoCards: React.FC<Props> = ({ cards = 1 }) => {
  let arrayCards: Array<number> = [];

  for (let i = 0; i < cards; i++) {
    arrayCards.push(i);
  }

  return (
    <>
      <div className="grade-produtos-carregando">
        {arrayCards.map((card, idx) => (
          <CardLoading key={idx} />
        ))}
      </div>
    </>
  );
};

export { CarregandoCards };
