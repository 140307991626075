const CardLoading = () => {
  return (
    <div className="produto card card-loading">
      <div className="card-img"></div>
      <div className="card-body">
        <div className="nome-produto text-center card-title esconder"></div>
        <div className="precos esconder mb-1"></div>
        <div className="div-quantidade esconder"></div>
      </div>
    </div>
  );
};

export { CardLoading };
