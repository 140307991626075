import "./rodape.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faCcMastercard, faCcVisa, faPix, faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { siteUrl } from "components/funcoesGlobais";
import { Col, Container, NavItem, NavLink, Row } from "react-bootstrap";
import { faCalendar, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useRodape } from "./useRodape";

const Rodape = () => {
  const empresa = useRodape();

  return (
    <footer className="pt-5">
      <div id="opcoes-rodape" className="pt-5 pb-3 bg-primary text-white">
        <Container>
          <Row>
            <Col className="col-6 col-md-3 mb-3">
              <h5>Informações</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <NavLink href="/" className="p-0 text-white">
                    Quem somos
                  </NavLink>
                </li>
                <li className="nav-item mb-2">
                  <NavLink href="/" className="p-0 text-white">
                    Nossas lojas
                  </NavLink>
                </li>
                <li className="nav-item mb-2">
                  <NavLink href="/" className="p-0 text-white">
                    Jornal de ofertas
                  </NavLink>
                </li>
                <li className="nav-item mb-2">
                  <NavLink href={empresa === undefined ? "/" : empresa.url_termo_privacidade} className="p-0 text-white" target="_blank">
                    Política de privacidade
                  </NavLink>
                </li>
                <li className="nav-item mb-2">
                  <NavLink href="/" className="p-0 text-white">
                    Contato
                  </NavLink>
                </li>
              </ul>
            </Col>
            <Col className="col-6 col-md-3 mb-3">
              <h5>SAC</h5>
              <ul className="nav flex-column">
                {empresa && empresa.telefone && empresa.telefone.trim() !== "" && (
                  <li className="nav-item mb-2">
                    <NavItem className="p-0 text-white">
                      <FontAwesomeIcon icon={faPhone} className="me-2" />
                      <span id="telefone">{empresa.telefone}</span>
                    </NavItem>
                  </li>
                )}
                {empresa && empresa.whatsapp && empresa.whatsapp.trim() !== "" && (
                  <li className="nav-item mb-2">
                    <NavLink
                      className="p-0 text-white"
                      href={`https://api.whatsapp.com/send?phone=55${empresa.whatsapp.replace(
                        /[^0-9]/g,
                        ""
                      )}&text=Ol%C3%A1!%20Estava%20utilizando%20a%20loja%20virtual%20e%20fiquei%20com%20algumas%20d%C3%BAvidas.%20Poderia%20me%20ajudar%3F`}
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} className="me-2" />
                      {empresa.whatsapp}
                    </NavLink>
                  </li>
                )}
                {empresa && empresa.email && empresa.email.trim() !== "" && (
                  <li className="nav-item mb-2">
                    <NavLink href={`mailto:${empresa.email}`} className="p-0 text-white">
                      <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                      {empresa.email}
                    </NavLink>
                  </li>
                )}
                {empresa && empresa.funcionamento && empresa.funcionamento.trim() !== "" && (
                  <li className="nav-item mb-2">
                    <NavLink href="/" className="p-0 text-white">
                      <FontAwesomeIcon icon={faCalendar} className="me-2" />
                      {empresa.funcionamento}
                    </NavLink>
                  </li>
                )}
              </ul>
            </Col>
            <Col className="col-6 col-md-3 mb-3">
              <h5>Acesso rápido</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <NavLink href="/" className="p-0 text-white">
                    Meus pedidos
                  </NavLink>
                </li>
                <li className="nav-item mb-2">
                  <NavLink href="/entrar" className="p-0 text-white">
                    Login
                  </NavLink>
                </li>
              </ul>
              <h5>Formas de pagamento</h5>
              <ul className="list-unstyled d-flex fs-3">
                <li>
                  <a className="link-light" href="/">
                    <FontAwesomeIcon icon={faCcMastercard} />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-light" href="/">
                    <FontAwesomeIcon icon={faCcVisa} />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-light" href="/">
                    <FontAwesomeIcon icon={faPix} />
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="col-6 col-md-3 mb-3">
              {empresa && (empresa.url_facebook || empresa.url_instagram || empresa.url_youtube) && (
                <>
                  <h5>Redes sociais</h5>
                  <ul className="list-unstyled d-flex fs-3">
                    {empresa.url_facebook && empresa.url_facebook.trim() !== "" && (
                      <li>
                        <a className="link-light" href={empresa.url_facebook.trim()}>
                          <FontAwesomeIcon icon={faFacebook} />
                        </a>
                      </li>
                    )}
                    {empresa.url_instagram && empresa.url_instagram.trim() !== "" && (
                      <li className="ms-3">
                        <a className="link-light" href={empresa.url_instagram.trim()}>
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>
                    )}
                    {empresa.url_youtube && empresa.url_youtube.trim() !== "" && (
                      <li className="ms-3">
                        <a className="link-light" href={empresa.url_youtube.trim()}>
                          <FontAwesomeIcon icon={faYoutube} />
                        </a>
                      </li>
                    )}
                  </ul>
                </>
              )}
              <h5>Site seguro</h5>
              <ul className="nav">
                <li className="nav-item me-2">
                  <NavLink className="p-0 text-muted">
                    <img src={siteUrl("imgs/icone_lets_encrypt.png", process.env.REACT_APP_SITE_URL)} alt="Let's Encrypt" height={30} />
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="p-0 text-muted">
                    <img src={siteUrl("imgs/icone_google_site_seguro.png", process.env.REACT_APP_SITE_URL)} alt="Google Site Seguro" height={30} />
                  </NavLink>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="pb-2">
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between py-4">
          <small className="text-muted">
            © {new Date().getFullYear()} {empresa?.razao_social} • Todos os direitos reservados.
            <br /> CNPJ: {empresa?.cnpj} - Endereço:
            {` ${empresa?.endereco}, ${empresa?.numero} ${empresa?.complemento?.trim() !== "" ? "- " + empresa?.complemento : ""} - ${empresa?.bairro} - ${empresa?.cidade}, ${
              empresa?.estado
            } - CEP: ${empresa?.cep}`}
          </small>
          <div>
            <small className="fw-bold text-muted me-2">Desenvolvido por:</small>
            <img src="/imgs/logos/logoprogen_250.png" alt="Logo ProgenBR" height="35" />
          </div>
        </div>
        <div id="footer-lei" className="py-4 border-top">
          A venda e o consumo de bebidas alcoólicas são proibidas para menores de 18 anos.
          <br />
          Em caso de divergência de preços no próprio site, é válido somente o valor do Carrinho de compras. Os produtos, preços e condições de pagamento são válidos exclusivamente para a loja
          eletrônica durante o dia de hoje, sujeitas a alterações sem prévia notificação. Os preços previstos no site prevalecem aos demais anunciados em quaisquer outros meios de comunicação,
          incluindo os veiculados em redes sociais, e-mail, sites de buscas ou meios impressos.
          <br />
          Os itens em promoção poderão ter quantidades limitadas por clientes de acordo com o artigo 39 – I CDC, Lei nº. 8.078 de 11/09/90 e artigo 12 – III Decreto nº. 2.181 de 20/03/97.
        </div>
      </Container>
    </footer>
  );
};

export { Rodape };
