import axios from "axios";
import { jsonParse, redirecionar, siteUrl } from "components/funcoesGlobais";

const api = axios.create({
  baseURL: siteUrl(""),
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.response.use(undefined, (erro) => {
  if (erro.response.status === 401) {
    localStorage.usuario = JSON.stringify({ logado: false });
    localStorage.removeItem("token");
    redirecionar("");
  }
});

if (localStorage.usuario) {
  try {
    const usuario = jsonParse("usuario");
    if (usuario.logado) api.defaults.headers.common["id-usuario"] = usuario.id;
  } catch (error) {
    localStorage.usuario = { logado: false };
  }
}

if (localStorage.token) api.defaults.headers.common["token-usuario"] = localStorage.token;

export default api;
