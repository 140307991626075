import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./alerta.css";
import { alertaProp } from "./alerta.interface";
import { useAlerta } from "./useAlerta";

const Alerta: React.FC<alertaProp> = (props) => {
  const { mensagem, setFechar, variante, className, icones } = useAlerta(props);

  return (
    <Alert variant={variante} className={`alerta ${className} d-flex align-items-center`} onClose={() => setFechar(false)} dismissible>
      <FontAwesomeIcon icon={icones[variante]} className="ps-1 pe-3" />
      <span>{mensagem}</span>
    </Alert>
  );
};

export { Alerta };
