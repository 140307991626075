import { Cabecalho } from "components/cabecalho/Cabecalho";
import { Rodape } from "components/rodape/Rodape";
import { deslogar, useMeusDados } from "./useMeusDados";
import "./meusDados.css";
import "bootstrap/js/dist/tooltip";
import { AbaPerfil } from "./perfil/Perfil";
import { Enderecos } from "./enderecos/Enderecos";

const MeusDados = () => {
  const abas: { [key: string]: JSX.Element } = { perfil: <AbaPerfil />, enderecos: <Enderecos /> };
  const { aba, usuario } = useMeusDados(abas);

  return (
    <>
      <Cabecalho />
      <div id="container-meus-dados" className="container">
        <div id="container-ola-usuario" className="containers py-3">
          <span className={`fs-5 titulos px-3`}>
            <strong>
              Olá, <span id="span-nome">{usuario.nome?.split(" ")[0]}</span>
            </strong>
          </span>
          <a className="btn-acoes-usuario" href="perfil" id="aba-perfil">
            Meu Perfil
          </a>
          <a className="btn-acoes-usuario" href="enderecos" id="aba-enderecos">
            Endereços
          </a>
          <a className="btn-acoes-usuario" href="pedidos" id="aba-pedidos">
            Meus Pedidos
          </a>
          <a className="btn-acoes-usuario" href="favoritos" id="aba-favoritos">
            Favoritos
          </a>
          <button className="btn-acoes-usuario border-top" onClick={deslogar}>
            Sair
          </button>
        </div>
        {aba && abas[aba] ? abas[aba] : abas.perfil}
      </div>
      <Rodape />
    </>
  );
};

export { MeusDados };
