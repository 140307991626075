import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./enderecos.css";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { formatarTel, keyUpTelefone, popularEndereco, removerEndereco, submitForm, ufEstados, useEnderecos, validarCampo, validarCep } from "./useEnderecos";
import { cpfValido } from "components/funcoesGlobais";
import { FormEndereco } from "./formEndereco.inteface";

const Enderecos = () => {
  const {
    register,
    trigger,
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
  } = useForm<FormEndereco>();

  const { tel, usuario, setAlerta, enderecos, exibirModal, consultandoEnd, setExibirAlerta, exibirModalConfirmar, setTel, setExibirModal, setAtualizarEnderecos } = useEnderecos();

  return (
    <>
      <div className="containers p-3 containers-abas">
        <span className="fs-5 titulos">
          <strong>Endereços</strong>
        </span>
        <div className="cards-enderecos mt-3 border-top pt-3">
          <div className="card-endereco card-novo-endereco shadow" onClick={() => [setExibirModal(true), reset()]}>
            <FontAwesomeIcon icon={faPlus} id="icone-adicionar-end" />
            <span>Novo Endereço</span>
          </div>
          {consultandoEnd ? (
            <div className="card-endereco shadow carregando"></div>
          ) : (
            enderecos.map((endereco: any) => (
              <div className="card-endereco shadow py-3 my-0" key={endereco.id} id={`endereco-${endereco.id}`}>
                {parseInt(endereco.padrao) === 1 && <div className="tag-padrao bg-primary">Padrão</div>}
                <div className="corpo-card">
                  <div className="descricao pb-3 pt-2">
                    <span>{endereco.descricao}</span>
                  </div>
                  <div className="endereco lh-1 pb-2">{`${endereco.logradouro}, ${endereco.numero} - ${endereco.bairro}, ${endereco.cidade} - ${endereco.estado} - ${endereco.cep.replace(
                    /(\d{5})(\d{3})/,
                    "$1-$2"
                  )}`}</div>
                  {endereco.telefone && (
                    <div className="telefone py-2">
                      <span className="text-muted">Telefone:</span>
                      <span className="lh-1">{formatarTel(endereco.telefone)}</span>
                    </div>
                  )}
                  {endereco.quem_recebe && endereco.cpf && (
                    <div className="quem-recebe py-2">
                      <span className="text-muted">Quem Recebe:</span>
                      <span className="lh-1">{endereco.quem_recebe}</span>
                      <span className="lh-1">{endereco.cpf.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}</span>
                    </div>
                  )}
                </div>
                <div className="botoes pt-2">
                  <Button variant="primary" size="sm" className="editar" onClick={() => [popularEndereco(endereco.id, setValue, setExibirModal, setTel, setAtualizarEnderecos), reset()]}>
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    className="remover"
                    onClick={() => removerEndereco(endereco.id, usuario.cliente, setAtualizarEnderecos, setAlerta, setExibirAlerta, exibirModalConfirmar)}
                  >
                    Remover
                  </Button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* MODAL DE INSERÇÃO DE ENDEREÇO */}
      <Modal show={exibirModal} onHide={() => [setExibirModal(false), reset()]} size="lg" id="modal-endereco" >
        <Modal.Header closeButton={true}>
          <Modal.Title>Novo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => submitForm(usuario, e, trigger, getValues, setExibirModal, setAlerta, setExibirAlerta, setAtualizarEnderecos)} id="form-endereco">
            <Form.Check type="switch" id="check-end-padrao" label="Endereço padrão" className="mb-3" />
            <div className="row">
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-descricao" label="Descrição para o endereço:" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Descrição para o endereço:"
                    onKeyUpCapture={() => validarCampo("descricao", trigger)}
                    {...register("descricao", {
                      required: "O campo descricao é obrigatório.",
                    })}
                    className={errors.descricao ? " is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.descricao?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-recebe" label="Nome de quem receberá o pedido:" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Nome de quem receberá o pedido:"
                    onKeyUpCapture={() => validarCampo("quem_recebe", trigger)}
                    {...register("quem_recebe", {
                      required: "O campo quem recebe é obrigatório.",
                    })}
                    className={errors.quem_recebe ? " is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.quem_recebe?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-cpf" label="CPF de quem receberá o pedido:" className="mb-2">
                  <ReactInputMask
                    mask={"999.999.999-99"}
                    placeholder="CPF de quem receberá o pedido:"
                    maskChar={""}
                    onKeyUpCapture={() => validarCampo("cpf", trigger)}
                    {...register("cpf", {
                      required: "O campo quem CPF é obrigatório.",
                      minLength: {
                        value: 14,
                        message: "CPF incompleto",
                      },
                      validate: {
                        cpf: (cpf) => {
                          return cpfValido(cpf) || "CPF inválido";
                        },
                      },
                    })}
                    className={`form-control ${errors.cpf ? " is-invalid" : ""}`}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.cpf?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-telefone" label="Telefone:" className="mb-2">
                  <ReactInputMask
                    mask={tel > 10 ? "(99) 99999-9999" : "(99) 9999-99999"}
                    placeholder="Telefone"
                    maskChar={""}
                    {...register("telefone", {
                      required: "O campo telefone é obrigatório",
                      validate: {
                        telefone: (telefone) => {
                          const limpo = telefone.replace(/\s|\(|\)|_|-/g, "");
                          const fixoMovel = limpo.length === 11 || limpo.length === 10;
                          return fixoMovel || "Telefone inválido";
                        },
                      },
                    })}
                    className={`form-control ${errors.telefone ? " is-invalid" : ""}`}
                    onKeyUpCapture={() => [keyUpTelefone(getValues, setTel), validarCampo("telefone", trigger)]}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.telefone?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-3">
                <FloatingLabel controlId="float-cep" label="CEP:" className="mb-2">
                  <ReactInputMask
                    mask={"99999-999"}
                    placeholder="CEP"
                    maskChar={""}
                    {...register("cep", {
                      required: "O campo CEP é obrigatório",
                      validate: {
                        cep: (cep) => {
                          const limpo = cep.replace(/\s|\(|\)|_|-/g, "");
                          return limpo.length === 8 || "CEP inválido";
                        },
                      },
                    })}
                    className={`form-control ${errors.cep ? " is-invalid" : getValues("cep") ? "is-valid" : ""}`}
                    onKeyUpCapture={() => [validarCep(trigger, getValues, setValue, setError), validarCampo("cep", trigger)]}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.cep?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-logradouro" label="Logradouro:" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Logradouro:"
                    onKeyUpCapture={() => validarCampo("logradouro", trigger)}
                    {...register("logradouro", {
                      required: "O campo logradouro é obrigatório.",
                    })}
                    className={errors.logradouro ? " is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.logradouro?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-3">
                <FloatingLabel controlId="float-numero" label="Número:" className="mb-2">
                  <Form.Control type="number" placeholder="Número:" onKeyUpCapture={() => validarCampo("numero", trigger)} {...register("numero")} className={errors.numero ? " is-invalid" : ""} />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.numero?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-complemento" label="Complemento:" className="mb-2">
                  <Form.Control
                    placeholder="Complemento:"
                    onKeyUpCapture={() => validarCampo("complemento", trigger)}
                    {...register("complemento")}
                    className={`form-control ${errors.complemento ? " is-invalid" : ""}`}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.complemento?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-bairro" label="Bairro:" className="mb-2">
                  <Form.Control
                    placeholder="Bairro:"
                    onKeyUpCapture={() => validarCampo("bairro", trigger)}
                    {...register("bairro", {
                      required: {
                        value: true,
                        message: "O campo bairro é obrigatório.",
                      },
                    })}
                    className={`form-control ${errors.bairro ? " is-invalid" : ""}`}
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.bairro?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <FloatingLabel controlId="float-cidade" label="Cidade:" className="mb-2">
                  <Form.Control
                    placeholder="Cidade:"
                    onKeyUpCapture={() => validarCampo("cidade", trigger)}
                    {...register("cidade", {
                      required: {
                        value: true,
                        message: "O campo cidade é obrigatório.",
                      },
                    })}
                    className={`form-control ${errors.cidade ? " is-invalid" : ""}`}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.cidade?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
              <div className="col-12 col-lg-6">
                <FloatingLabel label="Estado:">
                  <Form.Select
                    onKeyUpCapture={() => validarCampo("estado", trigger)}
                    {...register("estado", {
                      required: {
                        value: true,
                        message: "O campo estado é obrigatório.",
                      },
                    })}
                    className={errors.estado ? " is-invalid" : ""}
                    disabled
                  >
                    <option value="">Selecione</option>
                    {Object.entries(ufEstados).map((dado, i) => (
                      <option value={dado[0]} key={i}>{`${dado[1]} - ${dado[0]}`}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" className="lh-1 d-block">
                    {errors.estado?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </div>
            </div>
            <input type="hidden" {...register("id")} />
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="secondary" onClick={() => setExibirModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" form="form-endereco">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { Enderecos };
