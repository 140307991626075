import api from "services/api";
import { useEffect, useState } from "react";

export const useListarCategorias = () => {
  const [categorias, setCategorias] = useState([]);
  useEffect(() => {
    popularCategorias(setCategorias);
  }, []);
  return { categorias };
};

const popularCategorias = async (setCategorias: any) => {
  api.get("/setores.php?ecm=1&ativo=true&vinculados_produto=true").then((consulta) => {
    setCategorias(consulta.data.rows);
  });
};

export const exibirSubLista = (event: any) => {
  document.querySelectorAll(".lista-grupos").forEach((elemento) => {
    elemento?.classList.add("d-none");
  });

  document.querySelectorAll(".link-lista-grupos, .lista-grupos").forEach((elemento) => {
    elemento.classList.remove("mostrar");
  });

  event.target.nextElementSibling.classList.remove("d-none");

  setTimeout(() => {
    event.target.classList.add("mostrar");
    event.target.nextElementSibling.classList.add("mostrar");
  }, 10);
};
