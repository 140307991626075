import { Alerta } from "components/common/alerta/Alerta";
import { ModalConfirmar } from "components/common/modalConfirmar/ModalConfirmar";
import { createContext } from "react";
import { useUsuarioContext } from "./useUsuarioContext";

const UsuarioContext = createContext<{ [key: string]: any }>({});

const UsuarioProvider = (props: any) => {
  const { show, alerta, exibirAlerta, setExibirAlerta, setShow, setAlerta, exibirModalConfirmar } = useUsuarioContext();

  return (
    <UsuarioContext.Provider value={{ setExibirAlerta, setAlerta, exibirModalConfirmar }}>
      <ModalConfirmar show={show} setShow={setShow} />
      {exibirAlerta && <Alerta setFechar={setExibirAlerta} mensagem={alerta.mensagem} variante={alerta.variante} tempo={5000} />}
      {props.children}
    </UsuarioContext.Provider>
  );
};

export { UsuarioContext, UsuarioProvider };
