export const clickVoltar = (e: any) => {
  e.preventDefault();
  const carousel = e.currentTarget.closest(".container-carousel").firstChild;
  carousel.scrollLeft -= carousel.offsetWidth;
};

export const clickAvancar = (e: any) => {
  e.preventDefault();
  const carousel = e.currentTarget.closest(".container-carousel").firstChild;
  carousel.scrollLeft += carousel.offsetWidth;
};
