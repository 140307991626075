import "./carregando.css";

interface CarregandoDestaquesProp {
  setores: number;
}

const CarregandoDestaques: React.FC<CarregandoDestaquesProp> = ({ setores }) => {
  let arraySetores: Array<number> = [];

  for (let i = 0; i < setores; i++) {
    arraySetores.push(i);
  }

  return (
    <div id="categorias-destaque" className="bg-transparent">
      {arraySetores.map((a, idx) => (
        <div className="container-setor" key={idx}>
          <div className="setor-loading icone">
            <svg viewBox="0 0 80 80">
              <path d="M 0 0 H 80 V 80 H 0 z M 40 40 m -40 0 a 40 40 0 1 0 80 0 a 40 40 0 1 0 -80 0 z" />
            </svg>
          </div>
          <span className="descricao-carregando"></span>
        </div>
      ))}
    </div>
  );
};

export { CarregandoDestaques };
