import axios from "axios";
import { jsonParse, setUsuarioConsulta } from "components/funcoesGlobais";
import { useEffect, useState } from "react";
import api from "services/api";

export const verificarAlerta = (setAlerta: any, setExibirAlerta: any) => {
  const jsonAlerta = sessionStorage.getItem("alerta");
  if (jsonAlerta === null) return false;

  const objAlerta = JSON.parse(jsonAlerta);
  setAlerta(objAlerta);
  setExibirAlerta(true);
  sessionStorage.removeItem("alerta");
};

export const useDadosLocal = () => {
  const [usuario, setUsuario] = useState<{ [k: string]: any }>(jsonParse("usuario"));

  useEffect(() => {
    const consultasDadosLocais = async () => {
      const dados: { [i: string]: any } = {};
      dados.estoqueAtivo = jsonParse("estoqueAtivo").consultar !== undefined ? jsonParse("estoqueAtivo") : Object.assign(jsonParse("estoqueAtivo"), { consultar: true });

      //DADOS DA EMPRESA, CONSULTA CASO NÃO TENHA OS DADOS NO LS
      if (!localStorage.empresa) {
        await api.get("empresa.php").then((consulta) => {
          const empresa: any = {};

          Object.entries(consulta.data.empresa).forEach((dadoValor) => {
            if (camposEmpresa.includes(dadoValor[0])) empresa[dadoValor[0]] = dadoValor[1];
          });

          dados.empresa = empresa;
        });
      } else {
        dados.empresa = JSON.parse(localStorage.empresa);
      }

      //VERIFICA CASO TENHA UM TOKEN NO LS E REMOVE O TOKEN E USUARIO CASO SEJA INVÁLIDO
      if (localStorage.token) {
        if (!usuario.logado) {
          await api
            .get("usuarios.php/consultar_por_token", { params: { token_acesso: localStorage.token } })
            .then((consultaUsuario) => [setUsuarioConsulta(consultaUsuario.data.usuario), setUsuario(Object.assign({ logado: true }, consultaUsuario.data.usuario))])
            .catch(() => [setUsuario({ logado: false }), (dados.usuario = { logado: false })]);
        }
      } else if (Object.entries(usuario).length > 1) {
        dados.usuario = { logado: false };
        setUsuario(dados.usuario);
      }

      //VERIFICAÇÃO PELA GEOLOCALIZAÇÃO
      if (dados.estoqueAtivo.consultar) {
        const latLong: { [i: string]: any } = {};

        //TENTA PEGAR A GEOLOC PELO NAVEGADOR
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition((position) => resolve({ lat: position.coords.latitude, long: position.coords.longitude }), reject);
        })
          .then((coords: any) => {
            latLong.latitude = coords.lat;
            latLong.longitude = coords.long;
          })
          .catch((erro) => console.error(erro.message));

        //CASO CONSIGA CONSULTA O ENDEREÇO PELOS DADOS RECEBIDOS
        if (latLong.latitude && latLong.longitude) {
          await axios
            .post(
              process.env.REACT_APP_URL_API_CEP + "endereco.php",
              JSON.stringify({
                operacao: "CONSULTA",
                latitude: latLong.latitude,
                longitude: latLong.longitude,
              })
            )
            .then((consulta) => {
              if (consulta.data.resultado === true || consulta.data.resultado === "true") {
                dados.enderecoGeo = consulta.data.endereco;
                latLong.cep = dados.enderecoGeo.cep.toString().slice(0, 5) + "-" + dados.enderecoGeo.cep.toString().slice(5);
              }
            });
        }

        //CASO TENHA ENCONTRADO O ENDEREÇO PELAS COORDENADAS CONSULTADAS
        if (dados.enderecoGeo) {
          await api.get("estoque_armazens.php", { params: { cep_cliente: latLong.cep } }).then((consulta_cep) => {
            if (consulta_cep.data.total > 0) {
              dados.estoqueAtivo = consulta_cep.data.rows[0];
              dados.estoqueAtivo.consultar = false;
            } else {
              delete dados.enderecoGeo;
            }
          });
        }
      }

      //CASO NÃO ENCONTRE NENHUM ESTOQUE PARA O USUARIO, CONSULTA O PADRÃO (TENTA SOLICITAR AS CONSULTAS A CADA ATT ATÉ CONSEGUIR)
      if (Object.entries(dados.estoqueAtivo).length === 1) {
        await api
          .get("estoque_armazens.php", {
            params: { padrao: 1 },
          })
          .then((api) => {
            dados.estoqueAtivo = api.data.rows[0];
            dados.estoqueAtivo.consultar = true;
          });
      }

      Object.entries(dados).forEach((campoValor) => (localStorage[campoValor[0]] = JSON.stringify(campoValor[1])));
    };

    consultasDadosLocais();
    // eslint-disable-next-line
  }, []);
};

const camposEmpresa = [
  "url_termo_privacidade",
  "ocultar_precos",
  "cnpj",
  "endereco",
  "complemento",
  "bairro",
  "cidade",
  "estado",
  "razao_social",
  "exibir_aviso_bebida_alcoolica",
  "numero",
  "cep",
  "url_youtube",
  "url_instagram",
  "url_facebook",
  "funcionamento",
  "email",
  "whatsapp",
  "telefone",
];

export const useUsuarioContext = () => {
  const [show, setShow] = useState(false);
  const [alerta, setAlerta] = useState({ mensagem: "", variante: "success" });
  const [exibirAlerta, setExibirAlerta] = useState(false);

  useDadosLocal();
  verificarAlerta(setAlerta, setExibirAlerta);

  const exibirModalConfirmar = async (mensagem: string) => {
    await new Promise((resolve) => [setShow(true), resolve(true)]);

    const modal = document.getElementById("modal-confirmar");
    (modal?.querySelector("#texto-modal-confirmar") as HTMLDivElement).innerText = mensagem;

    return new Promise((resolve) => {
      modal?.querySelector(".btn-secondary")?.addEventListener("click", () => [setShow(false), resolve(false)]);
      modal?.querySelector(".btn-primary")?.addEventListener("click", () => [setShow(false), resolve(true)]);
    });
  };

  return { show, alerta, exibirAlerta, setExibirAlerta, setShow, setAlerta, exibirModalConfirmar };
};
